define("ember-svg-jar/inlined/ban", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.25 6.977q0-1.258-.68-2.305l-5.891 5.883q1.07.695 2.32.695.867 0 1.652-.34T9.006 10t.906-1.363.336-1.66zM2.445 9.313l5.898-5.891q-1.055-.711-2.344-.711-1.156 0-2.133.57T2.319 4.836t-.57 2.141q0 1.266.695 2.336zM12 6.977q0 1.227-.477 2.344t-1.277 1.922-1.914 1.281T6 13.001t-2.332-.477-1.914-1.281T.477 9.321 0 6.977t.477-2.34 1.277-1.918 1.914-1.281T6 .961t2.332.477 1.914 1.281 1.277 1.918.477 2.34z\"/>",
    "attrs": {
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14"
    }
  };
  _exports.default = _default;
});