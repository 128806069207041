define("ember-svg-jar/inlined/vimeo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#31AFD8\" d=\"M0 346c0-13.807 11.193-25 25-25s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z\"/><path fill=\"#FFF\" d=\"M39.152 341.169c-.133 2.92-2.173 6.918-6.118 11.993-4.08 5.3-7.53 7.95-10.352 7.95-1.75 0-3.23-1.612-4.438-4.841l-2.421-8.879c-.898-3.227-1.86-4.842-2.891-4.842-.225 0-1.01.472-2.355 1.413l-1.41-1.818c1.48-1.3 2.94-2.601 4.377-3.904 1.975-1.705 3.458-2.603 4.446-2.693 2.334-.225 3.771 1.37 4.31 4.786.584 3.686.987 5.978 1.213 6.875.674 3.058 1.415 4.585 2.224 4.585.627 0 1.57-.991 2.828-2.976 1.256-1.983 1.929-3.492 2.02-4.529.18-1.711-.494-2.57-2.02-2.57-.718 0-1.458.166-2.22.493 1.475-4.829 4.291-7.174 8.449-7.04 3.082.09 4.536 2.09 4.358 5.997\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 321 50 50"
    }
  };
  _exports.default = _default;
});