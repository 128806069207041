define("ember-svg-jar/inlined/cake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 .262s-.75 1.152-.75 1.571c0 .42.35.786.75.786s.75-.367.75-.786C5.75 1.414 5 .262 5 .262zm-1 2.88v1.572H2c-1.1 0-2 .943-2 2.096 0 .522.195.99.5 1.358V11h9V8.168c.305-.368.5-.836.5-1.358 0-1.153-.9-2.096-2-2.096H6V3.143H4zm-2 2.62h6c.55 0 1 .471 1 1.048 0 .576-.45 1.047-1 1.047-1.1 0-1.5-1.047-1.5-1.047S6 7.857 5 7.857 3.5 6.81 3.5 6.81 3.1 7.857 2 7.857c-.55 0-1-.471-1-1.047 0-.577.45-1.048 1-1.048z\" fill=\"#686868\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "10",
      "height": "11",
      "viewBox": "0 0 10 11"
    }
  };
  _exports.default = _default;
});