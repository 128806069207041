define("ember-svg-jar/inlined/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.567 4.73l-1.795-1.219a1.09 1.09 0 00-1.507.287l-8.787 12.959-4.039-4.039a1.09 1.09 0 00-1.533 0l-1.533 1.536a1.084 1.084 0 000 1.534L9.582 22c.349.347.895.615 1.387.615s.988-.31 1.307-.774l10.58-15.606a1.085 1.085 0 00-.289-1.505z\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});