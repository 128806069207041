define('@ember-data/model/-private', ['exports', '@ember-data/store/-private', '@ember-data/store'], function (exports, Private, store) { 'use strict';

  function computedMacroWithOptionalParams(fn) {
    {
      return fn;
    }
  }

  /**
    @module @ember-data/model
  */

  function getDefaultValue(record, options, key) {
    if (typeof options.defaultValue === 'function') {
      return options.defaultValue.apply(null, arguments);
    } else {
      var defaultValue = options.defaultValue;
      return defaultValue;
    }
  }

  function hasValue(internalModel, key) {
    return Private.recordDataFor(internalModel).hasAttr(key);
  }
  /**
    `attr` defines an attribute on a [Model](/ember-data/release/classes/Model).
    By default, attributes are passed through as-is, however you can specify an
    optional type to have the value automatically transformed.
    Ember Data ships with four basic transform types: `string`, `number`,
    `boolean` and `date`. You can define your own transforms by subclassing
    [Transform](/ember-data/release/classes/Transform).

    Note that you cannot use `attr` to define an attribute of `id`.

    `attr` takes an optional hash as a second parameter, currently
    supported options are:

    - `defaultValue`: Pass a string or a function to be called to set the attribute
    to a default value if and only if the key is absent from the payload response.

    Example

    ```app/models/user.js
    import Model, { attr } from '@ember-data/model';

    export default Model.extend({
      username: attr('string'),
      email: attr('string'),
      verified: attr('boolean', { defaultValue: false })
    });
    ```

    Default value can also be a function. This is useful it you want to return
    a new object for each attribute.

    ```app/models/user.js
    import Model, { attr } from '@ember-data/model';

    export default Model.extend({
      username: attr('string'),
      email: attr('string'),
      settings: attr({
        defaultValue() {
          return {};
        }
      })
    });
    ```

    The `options` hash is passed as second argument to a transforms'
    `serialize` and `deserialize` method. This allows to configure a
    transformation and adapt the corresponding value, based on the config:

    ```app/models/post.js
    import Model, { attr } from '@ember-data/model';

    export default Model.extend({
      text: attr('text', {
        uppercase: true
      })
    });
    ```

    ```app/transforms/text.js
    import Transform from '@ember-data/serializer/transform';

    export default Transform.extend({
      serialize(value, options) {
        if (options.uppercase) {
          return value.toUpperCase();
        }

        return value;
      },

      deserialize(value) {
        return value;
      }
    })
    ```

    @method attr
    @public
    @static
    @for @ember-data/model
    @param {String|Object} type the attribute type
    @param {Object} options a hash of options
    @return {Attribute}
  */


  function attr(type, options) {
    if (typeof type === 'object') {
      options = type;
      type = undefined;
    } else {
      options = options || {};
    }

    var meta = {
      type: type,
      isAttribute: true,
      kind: 'attribute',
      options: options
    };
    return Ember.computed({
      get: function get(key) {

        var internalModel = this._internalModel;

        if (hasValue(internalModel, key)) {
          return internalModel.getAttributeValue(key);
        } else {
          return getDefaultValue(this, options, key);
        }
      },
      set: function set(key, value) {

        return this._internalModel.setDirtyAttribute(key, value);
      }
    }).meta(meta);
  }

  var attr$1 = computedMacroWithOptionalParams(attr);

  /**
    @module @ember-data/model
  */

  /**
    `belongsTo` is used to define One-To-One and One-To-Many
    relationships on a [Model](/ember-data/release/classes/Model).


    `belongsTo` takes an optional hash as a second parameter, currently
    supported options are:

    - `async`: A boolean value used to explicitly declare this to be an async relationship. The default is true.
    - `inverse`: A string used to identify the inverse property on a
      related model in a One-To-Many relationship. See [Explicit Inverses](#explicit-inverses)
    - `polymorphic` A boolean value to mark the relationship as polymorphic

    #### One-To-One
    To declare a one-to-one relationship between two models, use
    `belongsTo`:

    ```app/models/user.js
    import Model, { belongsTo } from '@ember-data/model';

    export default Model.extend({
      profile: belongsTo('profile')
    });
    ```

    ```app/models/profile.js
    import Model, { belongsTo } from '@ember-data/model';

    export default Model.extend({
      user: belongsTo('user')
    });
    ```

    #### One-To-Many
    To declare a one-to-many relationship between two models, use
    `belongsTo` in combination with `hasMany`, like this:

    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';

    export default Model.extend({
      comments: hasMany('comment')
    });
    ```

    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';

    export default Model.extend({
      post: belongsTo('post')
    });
    ```

    You can avoid passing a string as the first parameter. In that case Ember Data
    will infer the type from the key name.

    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';

    export default Model.extend({
      post: belongsTo()
    });
    ```

    will lookup for a Post type.

    #### Sync relationships

    Ember Data resolves sync relationships with the related resources
    available in its local store, hence it is expected these resources
    to be loaded before or along-side the primary resource.

    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';

    export default Model.extend({
      post: belongsTo('post', {
        async: false
      })
    });
    ```

    In contrast to async relationship, accessing a sync relationship
    will always return the record (Model instance) for the existing
    local resource, or null. But it will error on access when
    a related resource is known to exist and it has not been loaded.

    ```
    let post = comment.get('post');

    ```

    @method belongsTo
    @public
    @static
    @for @ember-data/model
    @param {String} modelName (optional) type of the relationship
    @param {Object} options (optional) a hash of options
    @return {Ember.computed} relationship
  */

  function belongsTo(modelName, options) {
    var opts, userEnteredModelName;

    if (typeof modelName === 'object') {
      opts = modelName;
      userEnteredModelName = undefined;
    } else {
      opts = options;
      userEnteredModelName = modelName;
    }

    if (typeof userEnteredModelName === 'string') {
      userEnteredModelName = store.normalizeModelName(userEnteredModelName);
    }
    opts = opts || {};
    var meta = {
      type: userEnteredModelName,
      isRelationship: true,
      options: opts,
      kind: 'belongsTo',
      name: 'Belongs To',
      key: null
    };
    return Ember.computed({
      get: function get(key) {

        return this._internalModel.getBelongsTo(key);
      },
      set: function set(key, value) {

        this._internalModel.setDirtyBelongsTo(key, value);

        return this._internalModel.getBelongsTo(key);
      }
    }).meta(meta);
  }

  var belongsTo$1 = computedMacroWithOptionalParams(belongsTo);

  /**
    @module @ember-data/model
  */
  /**
    `hasMany` is used to define One-To-Many and Many-To-Many
    relationships on a [Model](/ember-data/release/classes/Model).

    `hasMany` takes an optional hash as a second parameter, currently
    supported options are:

    - `async`: A boolean value used to explicitly declare this to be an async relationship. The default is true.
    - `inverse`: A string used to identify the inverse property on a related model.
    - `polymorphic` A boolean value to mark the relationship as polymorphic

    #### One-To-Many
    To declare a one-to-many relationship between two models, use
    `belongsTo` in combination with `hasMany`, like this:

    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';

    export default Model.extend({
      comments: hasMany('comment')
    });
    ```

    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';

    export default Model.extend({
      post: belongsTo('post')
    });
    ```

    #### Many-To-Many
    To declare a many-to-many relationship between two models, use
    `hasMany`:

    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';

    export default Model.extend({
      tags: hasMany('tag')
    });
    ```

    ```app/models/tag.js
    import Model, { hasMany } from '@ember-data/model';

    export default Model.extend({
      posts: hasMany('post')
    });
    ```

    You can avoid passing a string as the first parameter. In that case Ember Data
    will infer the type from the singularized key name.

    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';

    export default Model.extend({
      tags: hasMany()
    });
    ```

    will lookup for a Tag type.

    #### Explicit Inverses

    Ember Data will do its best to discover which relationships map to
    one another. In the one-to-many code above, for example, Ember Data
    can figure out that changing the `comments` relationship should update
    the `post` relationship on the inverse because post is the only
    relationship to that model.

    However, sometimes you may have multiple `belongsTo`/`hasMany` for the
    same type. You can specify which property on the related model is
    the inverse using `hasMany`'s `inverse` option:

    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';

    export default Model.extend({
      onePost: belongsTo('post'),
      twoPost: belongsTo('post'),
      redPost: belongsTo('post'),
      bluePost: belongsTo('post')
    });
    ```

    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';

    export default Model.extend({
      comments: hasMany('comment', {
        inverse: 'redPost'
      })
    });
    ```

    You can also specify an inverse on a `belongsTo`, which works how
    you'd expect.

    #### Sync relationships

    Ember Data resolves sync relationships with the related resources
    available in its local store, hence it is expected these resources
    to be loaded before or along-side the primary resource.

    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';

    export default Model.extend({
      comments: hasMany('comment', {
        async: false
      })
    });
    ```

    In contrast to async relationship, accessing a sync relationship
    will always return a [ManyArray](/ember-data/release/classes/ManyArray) instance
    containing the existing local resources. But it will error on access
    when any of the known related resources have not been loaded.

    ```
    post.get('comments').forEach((comment) => {

    });

    ```

    If you are using `links` with sync relationships, you have to use
    `ref.reload` to fetch the resources.

    @method hasMany
    @public
    @static
    @for @ember-data/model
    @param {String} type (optional) type of the relationship
    @param {Object} options (optional) a hash of options
    @return {Ember.computed} relationship
  */

  function hasMany(type, options) {
    if (typeof type === 'object') {
      options = type;
      type = undefined;
    }
    options = options || {};

    if (typeof type === 'string') {
      type = store.normalizeModelName(type);
    } // Metadata about relationships is stored on the meta of
    // the relationship. This is used for introspection and
    // serialization. Note that `key` is populated lazily
    // the first time the CP is called.


    var meta = {
      type: type,
      options: options,
      isRelationship: true,
      kind: 'hasMany',
      name: 'Has Many',
      key: null
    };
    return Ember.computed({
      get: function get(key) {

        return this._internalModel.getHasMany(key);
      },
      set: function set(key, records) {

        var internalModel = this._internalModel;
        internalModel.setDirtyHasMany(key, records);
        return internalModel.getHasMany(key);
      }
    }).meta(meta);
  }

  var hasMany$1 = computedMacroWithOptionalParams(hasMany);

  /**
    @module @ember-data/store
  */

  /**
    Holds validation errors for a given record, organized by attribute names.

    Every `Model` has an `errors` property that is an instance of
    `Errors`. This can be used to display validation error
    messages returned from the server when a `record.save()` rejects.

    For Example, if you had a `User` model that looked like this:

    ```app/models/user.js
    import Model, { attr } from '@ember-data/model';

    export default Model.extend({
      username: attr('string'),
      email: attr('string')
    });
    ```
    And you attempted to save a record that did not validate on the backend:

    ```javascript
    let user = store.createRecord('user', {
      username: 'tomster',
      email: 'invalidEmail'
    });
    user.save();
    ```

    Your backend would be expected to return an error response that described
    the problem, so that error messages can be generated on the app.

    API responses will be translated into instances of `Errors` differently,
    depending on the specific combination of adapter and serializer used. You
    may want to check the documentation or the source code of the libraries
    that you are using, to know how they expect errors to be communicated.

    Errors can be displayed to the user by accessing their property name
    to get an array of all the error objects for that property. Each
    error object is a JavaScript object with two keys:

    - `message` A string containing the error message from the backend
    - `attribute` The name of the property associated with this error message

    ```handlebars
    <label>Username: {{input value=username}} </label>
    {{#each model.errors.username as |error|}}
      <div class="error">
        {{error.message}}
      </div>
    {{/each}}

    <label>Email: {{input value=email}} </label>
    {{#each model.errors.email as |error|}}
      <div class="error">
        {{error.message}}
      </div>
    {{/each}}
    ```

    You can also access the special `messages` property on the error
    object to get an array of all the error strings.

    ```handlebars
    {{#each model.errors.messages as |message|}}
      <div class="error">
        {{message}}
      </div>
    {{/each}}
    ```

    @class Errors
    @extends ArrayProxy
    @uses Evented
   */

  var Errors = Ember.ArrayProxy.extend(Private.DeprecatedEvented, {
    /**
      Register with target handler
       @method _registerHandlers
      @private
    */
    _registerHandlers: function _registerHandlers(becameInvalid, becameValid) {
      this._registeredHandlers = {
        becameInvalid: becameInvalid,
        becameValid: becameValid
      };
    },

    /**
      @property errorsByAttributeName
      @type {MapWithDefault}
      @private
    */
    errorsByAttributeName: Ember.computed(function () {
      return new Map();
    }),

    /**
      Returns errors for a given attribute
       ```javascript
      let user = store.createRecord('user', {
        username: 'tomster',
        email: 'invalidEmail'
      });
      user.save().catch(function(){
        user.get('errors').errorsFor('email'); // returns:
        // [{attribute: "email", message: "Doesn't look like a valid email."}]
      });
      ```
       @method errorsFor
      @param {String} attribute
      @return {Array}
    */
    errorsFor: function errorsFor(attribute) {
      var map = Ember.get(this, 'errorsByAttributeName');
      var errors = map.get(attribute);

      if (errors === undefined) {
        errors = Ember.A();
        map.set(attribute, errors);
      } // Errors may be a native array with extensions turned on. Since we access
      // the array via a method, and not a computed or using `Ember.get`, it does
      // not entangle properly with autotracking, so we entangle manually by
      // getting the `[]` property.


      Ember.get(errors, '[]');
      return errors;
    },

    /**
      An array containing all of the error messages for this
      record. This is useful for displaying all errors to the user.
       ```handlebars
      {{#each model.errors.messages as |message|}}
        <div class="error">
          {{message}}
        </div>
      {{/each}}
      ```
       @property messages
      @type {Array}
    */
    messages: Ember.computed.mapBy('content', 'message'),

    /**
      @property content
      @type {Array}
      @private
    */
    content: Ember.computed(function () {
      return Ember.A();
    }),

    /**
      @method unknownProperty
      @private
    */
    unknownProperty: function unknownProperty(attribute) {
      var errors = this.errorsFor(attribute);

      if (errors.length === 0) {
        return undefined;
      }

      return errors;
    },

    /**
      Total number of errors.
       @property length
      @type {Number}
      @readOnly
    */

    /**
      @property isEmpty
      @type {Boolean}
      @readOnly
    */
    isEmpty: Ember.computed.not('length').readOnly(),

    /**
     Manually adds errors to the record. This will trigger the `becameInvalid` event/ lifecycle method on
      the record and transition the record into an `invalid` state.
      Example
     ```javascript
      let errors = get(user, 'errors');
       // add multiple errors
      errors.add('password', [
        'Must be at least 12 characters',
        'Must contain at least one symbol',
        'Cannot contain your name'
      ]);
       errors.errorsFor('password');
      // =>
      // [
      //   { attribute: 'password', message: 'Must be at least 12 characters' },
      //   { attribute: 'password', message: 'Must contain at least one symbol' },
      //   { attribute: 'password', message: 'Cannot contain your name' },
      // ]
       // add a single error
      errors.add('username', 'This field is required');
       errors.errorsFor('password');
      // =>
      // [
      //   { attribute: 'username', message: 'This field is required' },
      // ]
     ```
    @method add
    @param {string} attribute - the property name of an attribute or relationship
    @param {string[]|string} messages - an error message or array of error messages for the attribute
     */
    add: function add(attribute, messages) {
      var wasEmpty = Ember.get(this, 'isEmpty');

      this._add(attribute, messages);

      if (wasEmpty && !Ember.get(this, 'isEmpty')) {
        this._registeredHandlers && this._registeredHandlers.becameInvalid();
      }
    },

    /**
      Adds error messages to a given attribute without sending event.
       @method _add
      @private
    */
    _add: function _add(attribute, messages) {
      messages = this._findOrCreateMessages(attribute, messages);
      this.addObjects(messages);
      this.errorsFor(attribute).addObjects(messages);
      this.notifyPropertyChange(attribute);
    },

    /**
      @method _findOrCreateMessages
      @private
    */
    _findOrCreateMessages: function _findOrCreateMessages(attribute, messages) {
      var errors = this.errorsFor(attribute);
      var messagesArray = Ember.makeArray(messages);

      var _messages = new Array(messagesArray.length);

      for (var i = 0; i < messagesArray.length; i++) {
        var message = messagesArray[i];
        var err = errors.findBy('message', message);

        if (err) {
          _messages[i] = err;
        } else {
          _messages[i] = {
            attribute: attribute,
            message: message
          };
        }
      }

      return _messages;
    },

    /**
     Manually removes all errors for a given member from the record.
       This will transition the record into a `valid` state, and
      triggers the `becameValid` event and lifecycle method.
      Example:
      ```javascript
      let errors = get('user', errors);
      errors.add('phone', ['error-1', 'error-2']);
       errors.errorsFor('phone');
      // =>
      // [
      //   { attribute: 'phone', message: 'error-1' },
      //   { attribute: 'phone', message: 'error-2' },
      // ]
       errors.remove('phone');
       errors.errorsFor('phone');
      // => undefined
     ```
     @method remove
     @param {string} member - the property name of an attribute or relationship
     */
    remove: function remove(attribute) {
      if (Ember.get(this, 'isEmpty')) {
        return;
      }

      this._remove(attribute);

      if (Ember.get(this, 'isEmpty')) {
        this._registeredHandlers && this._registeredHandlers.becameValid();
      }
    },

    /**
      Removes all error messages from the given attribute without sending event.
       @method _remove
      @private
    */
    _remove: function _remove(attribute) {
      if (Ember.get(this, 'isEmpty')) {
        return;
      }

      var content = this.rejectBy('attribute', attribute);
      Ember.get(this, 'content').setObjects(content);
      Ember.get(this, 'errorsByAttributeName').delete(attribute);
      this.notifyPropertyChange(attribute);
      this.notifyPropertyChange('length');
    },

    /**
     Manually clears all errors for the record.
       This will transition the record into a `valid` state, and
       will trigger the `becameValid` event and lifecycle method.
     Example:
      ```javascript
     let errors = get('user', errors);
     errors.add('username', ['error-a']);
     errors.add('phone', ['error-1', 'error-2']);
      errors.errorsFor('username');
     // =>
     // [
     //   { attribute: 'username', message: 'error-a' },
     // ]
      errors.errorsFor('phone');
     // =>
     // [
     //   { attribute: 'phone', message: 'error-1' },
     //   { attribute: 'phone', message: 'error-2' },
     // ]
      errors.clear();
      errors.errorsFor('username');
     // => undefined
      errors.errorsFor('phone');
     // => undefined
      errors.get('messages')
     // => []
     ```
     @method remove
     */
    clear: function clear() {
      if (Ember.get(this, 'isEmpty')) {
        return;
      }

      this._clear();

      this._registeredHandlers && this._registeredHandlers.becameValid();
    },

    /**
      Removes all error messages.
      to the record.
       @method _clear
      @private
    */
    _clear: function _clear() {
      var _this = this;

      if (Ember.get(this, 'isEmpty')) {
        return;
      }

      var errorsByAttributeName = Ember.get(this, 'errorsByAttributeName');
      var attributes = [];
      errorsByAttributeName.forEach(function (_, attribute) {
        attributes.push(attribute);
      });
      errorsByAttributeName.clear();
      attributes.forEach(function (attribute) {
        _this.notifyPropertyChange(attribute);
      });
      Ember.ArrayProxy.prototype.clear.call(this);
    },

    /**
      Checks if there are error messages for the given attribute.
       ```app/routes/user/edit.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          save: function(user) {
            if (user.get('errors').has('email')) {
              return alert('Please update your email before attempting to save.');
            }
            user.save();
          }
        }
      });
      ```
       @method has
      @param {String} attribute
      @return {Boolean} true if there some errors on given attribute
    */
    has: function has(attribute) {
      return this.errorsFor(attribute).length > 0;
    }
  });

  /**
    @module @ember-data/model
  */

  var relationshipsDescriptor = Ember.computed(function () {
    var map = new Map();
    var relationshipsByName = Ember.get(this, 'relationshipsByName'); // Loop through each computed property on the class

    relationshipsByName.forEach(function (desc) {
      var type = desc.type;

      if (!map.has(type)) {
        map.set(type, []);
      }

      map.get(type).push(desc);
    });
    return map;
  }).readOnly();
  var relatedTypesDescriptor = Ember.computed(function () {

    var parentModelName = this.modelName;
    var types = Ember.A(); // Loop through each computed property on the class,
    // and create an array of the unique types involved
    // in relationships

    this.eachComputedProperty(function (name, meta) {
      if (meta.isRelationship) {
        meta.key = name;
        var modelName = Private.typeForRelationshipMeta(meta);

        if (!types.includes(modelName)) {
          types.push(modelName);
        }
      }
    });
    return types;
  }).readOnly();
  var relationshipsObjectDescriptor = Ember.computed(function () {
    var relationships = Object.create(null);
    var modelName = this.modelName;
    this.eachComputedProperty(function (name, meta) {
      if (meta.isRelationship) {
        meta.key = name;
        meta.name = name;
        meta.parentModelName = modelName;
        relationships[name] = Private.relationshipFromMeta(meta);
      }
    });
    return relationships;
  });
  var relationshipsByNameDescriptor = Ember.computed(function () {
    var map = new Map();
    var rels = Ember.get(this, 'relationshipsObject');
    var relationships = Object.keys(rels);

    for (var i = 0; i < relationships.length; i++) {
      var key = relationships[i];
      var value = rels[key];
      map.set(value.key, value);
    }

    return map;
  }).readOnly();

  var changeProperties = Ember.changeProperties;

  function findPossibleInverses(type, inverseType, name, relationshipsSoFar) {
    var possibleRelationships = relationshipsSoFar || [];
    var relationshipMap = Ember.get(inverseType, 'relationships');

    if (!relationshipMap) {
      return possibleRelationships;
    }

    var relationshipsForType = relationshipMap.get(type.modelName);
    var relationships = Array.isArray(relationshipsForType) ? relationshipsForType.filter(function (relationship) {
      var optionsForRelationship = inverseType.metaForProperty(relationship.name).options;

      if (!optionsForRelationship.inverse && optionsForRelationship.inverse !== null) {
        return true;
      }

      return name === optionsForRelationship.inverse;
    }) : null;

    if (relationships) {
      possibleRelationships.push.apply(possibleRelationships, relationships);
    } //Recurse to support polymorphism


    if (type.superclass) {
      findPossibleInverses(type.superclass, inverseType, name, possibleRelationships);
    }

    return possibleRelationships;
  }

  var retrieveFromCurrentState = Ember.computed('currentState', function (key) {
    return Ember.get(this._internalModel.currentState, key);
  }).readOnly();
  var isValidRecordData = Ember.computed('errors.length', function (key) {
    return !(this.get('errors.length') > 0);
  }).readOnly();
  var isValid =  retrieveFromCurrentState;
  var isDeletedCP;

  {
    isDeletedCP = retrieveFromCurrentState;
  }

  var isNewCP;

  {
    isNewCP = retrieveFromCurrentState;
  }

  var adapterError;

  {
    adapterError = null;
  }

  var isError;

  {
    isError = false;
  }

  var isReloading;

  {
    isReloading = false;
  }
  /**
    @class Model
    @module @ember-data/model
    @extends EmberObject
    @uses EmberData.DeprecatedEvented
  */


  var Model = Ember.Object.extend(Private.DeprecatedEvented, {
    init: function init() {

      this._super.apply(this, arguments);
    },
    _notifyNetworkChanges: function _notifyNetworkChanges() {
      var _this2 = this;

      {
        ['isValid'].forEach(function (key) {
          return _this2.notifyPropertyChange(key);
        });
      }
    },

    /**
      If this property is `true` the record is in the `empty`
      state. Empty is the first state all records enter after they have
      been created. Most records created by the store will quickly
      transition to the `loading` state if data needs to be fetched from
      the server or the `created` state if the record is created on the
      client. A record can also enter the empty state if the adapter is
      unable to locate the record.
       @property isEmpty
      @type {Boolean}
      @readOnly
    */
    isEmpty: retrieveFromCurrentState,

    /**
      If this property is `true` the record is in the `loading` state. A
      record enters this state when the store asks the adapter for its
      data. It remains in this state until the adapter provides the
      requested data.
       @property isLoading
      @type {Boolean}
      @readOnly
    */
    isLoading: retrieveFromCurrentState,

    /**
      If this property is `true` the record is in the `loaded` state. A
      record enters this state when its data is populated. Most of a
      record's lifecycle is spent inside substates of the `loaded`
      state.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.get('isLoaded'); // true
       store.findRecord('model', 1).then(function(model) {
        model.get('isLoaded'); // true
      });
      ```
       @property isLoaded
      @type {Boolean}
      @readOnly
    */
    isLoaded: retrieveFromCurrentState,

    /**
      If this property is `true` the record is in the `dirty` state. The
      record has local changes that have not yet been saved by the
      adapter. This includes records that have been created (but not yet
      saved) or deleted.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.get('hasDirtyAttributes'); // true
       store.findRecord('model', 1).then(function(model) {
        model.get('hasDirtyAttributes'); // false
        model.set('foo', 'some value');
        model.get('hasDirtyAttributes'); // true
      });
      ```
       @since 1.13.0
      @property hasDirtyAttributes
      @type {Boolean}
      @readOnly
    */
    hasDirtyAttributes: Ember.computed('currentState.isDirty', function () {
      return this.get('currentState.isDirty');
    }),

    /**
      If this property is `true` the record is in the `saving` state. A
      record enters the saving state when `save` is called, but the
      adapter has not yet acknowledged that the changes have been
      persisted to the backend.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.get('isSaving'); // false
      let promise = record.save();
      record.get('isSaving'); // true
      promise.then(function() {
        record.get('isSaving'); // false
      });
      ```
       @property isSaving
      @type {Boolean}
      @readOnly
    */
    isSaving: retrieveFromCurrentState,

    /**
      If this property is `true` the record is in the `deleted` state
      and has been marked for deletion. When `isDeleted` is true and
      `hasDirtyAttributes` is true, the record is deleted locally but the deletion
      was not yet persisted. When `isSaving` is true, the change is
      in-flight. When both `hasDirtyAttributes` and `isSaving` are false, the
      change has persisted.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.get('isDeleted');    // false
      record.deleteRecord();
       // Locally deleted
      record.get('isDeleted');           // true
      record.get('hasDirtyAttributes');  // true
      record.get('isSaving');            // false
       // Persisting the deletion
      let promise = record.save();
      record.get('isDeleted');    // true
      record.get('isSaving');     // true
       // Deletion Persisted
      promise.then(function() {
        record.get('isDeleted');          // true
        record.get('isSaving');           // false
        record.get('hasDirtyAttributes'); // false
      });
      ```
       @property isDeleted
      @type {Boolean}
      @readOnly
    */
    isDeleted: isDeletedCP,

    /**
      If this property is `true` the record is in the `new` state. A
      record will be in the `new` state when it has been created on the
      client and the adapter has not yet report that it was successfully
      saved.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.get('isNew'); // true
       record.save().then(function(model) {
        model.get('isNew'); // false
      });
      ```
       @property isNew
      @type {Boolean}
      @readOnly
    */
    isNew: isNewCP,

    /**
      If this property is `true` the record is in the `valid` state.
       A record will be in the `valid` state when the adapter did not report any
      server-side validation failures.
       @property isValid
      @type {Boolean}
      @readOnly
    */
    isValid: isValid,
    _markInvalidRequestAsClean: function _markInvalidRequestAsClean() {
    },

    /**
      If the record is in the dirty state this property will report what
      kind of change has caused it to move into the dirty
      state. Possible values are:
       - `created` The record has been created by the client and not yet saved to the adapter.
      - `updated` The record has been updated by the client and not yet saved to the adapter.
      - `deleted` The record has been deleted by the client and not yet saved to the adapter.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.get('dirtyType'); // 'created'
      ```
       @property dirtyType
      @type {String}
      @readOnly
    */
    dirtyType: retrieveFromCurrentState,

    /**
      If `true` the adapter reported that it was unable to save local
      changes to the backend for any reason other than a server-side
      validation error.
       Example
       ```javascript
      record.get('isError'); // false
      record.set('foo', 'valid value');
      record.save().then(null, function() {
        record.get('isError'); // true
      });
      ```
       @property isError
      @type {Boolean}
      @readOnly
    */
    isError: isError,
    _markErrorRequestAsClean: function _markErrorRequestAsClean() {
      this._errorRequests = [];
      this._lastError = null;

      this._notifyNetworkChanges();
    },

    /**
      If `true` the store is attempting to reload the record from the adapter.
       Example
       ```javascript
      record.get('isReloading'); // false
      record.reload();
      record.get('isReloading'); // true
      ```
       @property isReloading
      @type {Boolean}
      @readOnly
    */
    isReloading: isReloading,

    /**
      All ember models have an id property. This is an identifier
      managed by an external source. These are always coerced to be
      strings before being used internally. Note when declaring the
      attributes for a model it is an error to declare an id
      attribute.
       ```javascript
      let record = store.createRecord('model');
      record.get('id'); // null
       store.findRecord('model', 1).then(function(model) {
        model.get('id'); // '1'
      });
      ```
       @property id
      @type {String}
    */

    /**
      @property currentState
      @private
      @type {Object}
    */
    currentState: Private.RootState.empty,
    // defined here to avoid triggering setUnknownProperty

    /**
     @property _internalModel
     @private
     @type {Object}
     */
    _internalModel: null,
    // defined here to avoid triggering setUnknownProperty

    /**
     @property recordData
     @private
     @type undefined (reserved)
     */
    // will be defined here to avoid triggering setUnknownProperty

    /**
     @property store
     */
    store: null,
    // defined here to avoid triggering setUnknownProperty

    /**
      When the record is in the `invalid` state this object will contain
      any errors returned by the adapter. When present the errors hash
      contains keys corresponding to the invalid property names
      and values which are arrays of Javascript objects with two keys:
       - `message` A string containing the error message from the backend
      - `attribute` The name of the property associated with this error message
       ```javascript
      record.get('errors.length'); // 0
      record.set('foo', 'invalid value');
      record.save().catch(function() {
        record.get('errors').get('foo');
        // [{message: 'foo should be a number.', attribute: 'foo'}]
      });
      ```
       The `errors` property us useful for displaying error messages to
      the user.
       ```handlebars
      <label>Username: {{input value=username}} </label>
      {{#each model.errors.username as |error|}}
        <div class="error">
          {{error.message}}
        </div>
      {{/each}}
      <label>Email: {{input value=email}} </label>
      {{#each model.errors.email as |error|}}
        <div class="error">
          {{error.message}}
        </div>
      {{/each}}
      ```
        You can also access the special `messages` property on the error
      object to get an array of all the error strings.
       ```handlebars
      {{#each model.errors.messages as |message|}}
        <div class="error">
          {{message}}
        </div>
      {{/each}}
      ```
       @property errors
      @type {Errors}
    */
    errors: Ember.computed(function () {
      var _this3 = this;

      var errors = Errors.create();

      errors._registerHandlers(function () {
        _this3.send('becameInvalid');
      }, function () {
        _this3.send('becameValid');
      });

      return errors;
    }).readOnly(),
    invalidErrorsChanged: function invalidErrorsChanged(jsonApiErrors) {
    },
    _addErrorMessageToAttribute: function _addErrorMessageToAttribute(attribute, message) {
      this.get('errors')._add(attribute, message);
    },
    _clearErrorMessages: function _clearErrorMessages() {
      this.get('errors')._clear();
    },

    /**
      This property holds the `AdapterError` object with which
      last adapter operation was rejected.
       @property adapterError
      @type {AdapterError}
    */
    adapterError: adapterError,

    /**
      Create a JSON representation of the record, using the serialization
      strategy of the store's adapter.
      `serialize` takes an optional hash as a parameter, currently
      supported options are:
      - `includeId`: `true` if the record's ID should be included in the
        JSON representation.
       @method serialize
      @param {Object} options
      @return {Object} an object whose values are primitive JSON values only
    */
    serialize: function serialize(options) {
      return this._internalModel.createSnapshot().serialize(options);
    },

    /**
      Fired when the record is ready to be interacted with,
      that is either loaded from the server or created locally.
       @event ready
    */
    ready: null,

    /**
      Fired when the record is loaded from the server.
       @event didLoad
    */
    didLoad: null,

    /**
      Fired when the record is updated.
       @event didUpdate
    */
    didUpdate: null,

    /**
      Fired when a new record is commited to the server.
       @event didCreate
    */
    didCreate: null,

    /**
      Fired when the record is deleted.
       @event didDelete
    */
    didDelete: null,

    /**
      Fired when the record becomes invalid.
       @event becameInvalid
    */
    becameInvalid: null,

    /**
      Fired when the record enters the error state.
       @event becameError
    */
    becameError: null,

    /**
      Fired when the record is rolled back.
       @event rolledBack
    */
    rolledBack: null,
    //TODO Do we want to deprecate these?

    /**
      @method send
      @private
      @param {String} name
      @param {Object} context
    */
    send: function send(name, context) {
      return this._internalModel.send(name, context);
    },

    /**
      @method transitionTo
      @private
      @param {String} name
    */
    transitionTo: function transitionTo(name) {
      return this._internalModel.transitionTo(name);
    },

    /**
      Marks the record as deleted but does not save it. You must call
      `save` afterwards if you want to persist it. You might use this
      method if you want to allow the user to still `rollbackAttributes()`
      after a delete was made.
       Example
       ```app/routes/model/delete.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          softDelete() {
            this.get('controller.model').deleteRecord();
          },
          confirm() {
            this.get('controller.model').save();
          },
          undo() {
            this.get('controller.model').rollbackAttributes();
          }
        }
      });
      ```
       @method deleteRecord
    */
    deleteRecord: function deleteRecord() {
      this._internalModel.deleteRecord();
    },

    /**
      Same as `deleteRecord`, but saves the record immediately.
       Example
       ```app/routes/model/delete.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          delete() {
            this.get('controller.model').destroyRecord().then(function() {
              controller.transitionToRoute('model.index');
            });
          }
        }
      });
      ```
       If you pass an object on the `adapterOptions` property of the options
      argument it will be passed to your adapter via the snapshot
       ```js
      record.destroyRecord({ adapterOptions: { subscribe: false } });
      ```
       ```app/adapters/post.js
      import MyCustomAdapter from './custom-adapter';
       export default MyCustomAdapter.extend({
        deleteRecord(store, type, snapshot) {
          if (snapshot.adapterOptions.subscribe) {
            // ...
          }
          // ...
        }
      });
      ```
       @method destroyRecord
      @param {Object} options
      @return {Promise} a promise that will be resolved when the adapter returns
      successfully or rejected if the adapter returns with an error.
    */
    destroyRecord: function destroyRecord(options) {
      this.deleteRecord();
      return this.save(options);
    },

    /**
      Unloads the record from the store. This will not send a delete request
      to your server, it just unloads the record from memory.
       @method unloadRecord
    */
    unloadRecord: function unloadRecord() {
      if (this.isDestroyed) {
        return;
      }

      this._internalModel.unloadRecord();
    },

    /**
      @method _notifyProperties
      @private
    */
    _notifyProperties: function _notifyProperties(keys) {
      var _this4 = this;

      // changeProperties defers notifications until after the delegate
      // and protects with a try...finally block
      // previously used begin...endPropertyChanges but this is private API
      changeProperties(function () {
        var key;

        for (var i = 0, length = keys.length; i < length; i++) {
          key = keys[i];

          _this4.notifyPropertyChange(key);
        }
      });
    },

    /**
      Returns an object, whose keys are changed properties, and value is
      an [oldProp, newProp] array.
       The array represents the diff of the canonical state with the local state
      of the model. Note: if the model is created locally, the canonical state is
      empty since the adapter hasn't acknowledged the attributes yet:
       Example
       ```app/models/mascot.js
      import Model, { attr } from '@ember-data/model';
       export default Model.extend({
        name: attr('string'),
        isAdmin: attr('boolean', {
          defaultValue: false
        })
      });
      ```
       ```javascript
      let mascot = store.createRecord('mascot');
       mascot.changedAttributes(); // {}
       mascot.set('name', 'Tomster');
      mascot.changedAttributes(); // { name: [undefined, 'Tomster'] }
       mascot.set('isAdmin', true);
      mascot.changedAttributes(); // { isAdmin: [undefined, true], name: [undefined, 'Tomster'] }
       mascot.save().then(function() {
        mascot.changedAttributes(); // {}
         mascot.set('isAdmin', false);
        mascot.changedAttributes(); // { isAdmin: [true, false] }
      });
      ```
       @method changedAttributes
      @return {Object} an object, whose keys are changed properties,
        and value is an [oldProp, newProp] array.
    */
    changedAttributes: function changedAttributes() {
      return this._internalModel.changedAttributes();
    },

    /**
      If the model `hasDirtyAttributes` this function will discard any unsaved
      changes. If the model `isNew` it will be removed from the store.
       Example
       ```javascript
      record.get('name'); // 'Untitled Document'
      record.set('name', 'Doc 1');
      record.get('name'); // 'Doc 1'
      record.rollbackAttributes();
      record.get('name'); // 'Untitled Document'
      ```
       @since 1.13.0
      @method rollbackAttributes
    */
    rollbackAttributes: function rollbackAttributes() {
      this._internalModel.rollbackAttributes();
    },

    /*
      @method _createSnapshot
      @private
    */
    _createSnapshot: function _createSnapshot() {
      return this._internalModel.createSnapshot();
    },
    toStringExtension: function toStringExtension() {
      // the _internalModel guard exists, because some dev-only deprecation code
      // (addListener via validatePropertyInjections) invokes toString before the
      // object is real.
      return this._internalModel && this._internalModel.id;
    },

    /**
      Save the record and persist any changes to the record to an
      external source via the adapter.
       Example
       ```javascript
      record.set('name', 'Tomster');
      record.save().then(function() {
        // Success callback
      }, function() {
        // Error callback
      });
      ```
      If you pass an object using the `adapterOptions` property of the options
     argument it will be passed to your adapter via the snapshot.
       ```js
      record.save({ adapterOptions: { subscribe: false } });
      ```
       ```app/adapters/post.js
      import MyCustomAdapter from './custom-adapter';
       export default MyCustomAdapter.extend({
        updateRecord(store, type, snapshot) {
          if (snapshot.adapterOptions.subscribe) {
            // ...
          }
          // ...
        }
      });
      ```
       @method save
      @param {Object} options
      @return {Promise} a promise that will be resolved when the adapter returns
      successfully or rejected if the adapter returns with an error.
    */
    save: function save(options) {
      var _this5 = this;

      return Private.PromiseObject.create({
        promise: this._internalModel.save(options).then(function () {
          return _this5;
        })
      });
    },

    /**
      Reload the record from the adapter.
       This will only work if the record has already finished loading.
       Example
       ```app/routes/model/view.js
      import Route from '@ember/routing/route';
       export default Route.extend({
        actions: {
          reload() {
            this.controller.get('model').reload().then(function(model) {
              // do something with the reloaded model
            });
          }
        }
      });
      ```
       @method reload
      @param {Object} options optional, may include `adapterOptions` hash which will be passed to adapter request
      @return {Promise} a promise that will be resolved with the record when the
      adapter returns successfully or rejected if the adapter returns
      with an error.
    */
    reload: function reload(options) {
      var _this6 = this;

      var wrappedAdapterOptions;

      if (typeof options === 'object' && options !== null && options.adapterOptions) {
        wrappedAdapterOptions = {
          adapterOptions: options.adapterOptions
        };
      }

      return Private.PromiseObject.create({
        promise: this._internalModel.reload(wrappedAdapterOptions).then(function () {
          return _this6;
        })
      });
    },
    attr: function attr() {
    },

    /**
      Get the reference for the specified belongsTo relationship.
       Example
       ```app/models/blog.js
      import Model, { belongsTo } from '@ember-data/model';
       export default Model.extend({
        user: belongsTo({ async: true })
      });
      ```
       ```javascript
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
      let userRef = blog.belongsTo('user');
       // check if the user relationship is loaded
      let isLoaded = userRef.value() !== null;
       // get the record of the reference (null if not yet available)
      let user = userRef.value();
       // get the identifier of the reference
      if (userRef.remoteType() === "id") {
        let id = userRef.id();
      } else if (userRef.remoteType() === "link") {
        let link = userRef.link();
      }
       // load user (via store.findRecord or store.findBelongsTo)
      userRef.load().then(...)
       // or trigger a reload
      userRef.reload().then(...)
       // provide data for reference
      userRef.push({
        type: 'user',
        id: 1,
        attributes: {
          username: "@user"
        }
      }).then(function(user) {
        userRef.value() === user;
      });
      ```
       @method belongsTo
      @param {String} name of the relationship
      @since 2.5.0
      @return {BelongsToReference} reference for this relationship
    */
    belongsTo: function belongsTo(name) {
      return this._internalModel.referenceFor('belongsTo', name);
    },

    /**
      Get the reference for the specified hasMany relationship.
       Example
       ```app/models/blog.js
      import Model, { hasMany } from '@ember-data/model';
       export default Model.extend({
        comments: hasMany({ async: true })
      });
       let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            comments: {
              data: [
                { type: 'comment', id: 1 },
                { type: 'comment', id: 2 }
              ]
            }
          }
        }
      });
      let commentsRef = blog.hasMany('comments');
       // check if the comments are loaded already
      let isLoaded = commentsRef.value() !== null;
       // get the records of the reference (null if not yet available)
      let comments = commentsRef.value();
       // get the identifier of the reference
      if (commentsRef.remoteType() === "ids") {
        let ids = commentsRef.ids();
      } else if (commentsRef.remoteType() === "link") {
        let link = commentsRef.link();
      }
       // load comments (via store.findMany or store.findHasMany)
      commentsRef.load().then(...)
       // or trigger a reload
      commentsRef.reload().then(...)
       // provide data for reference
      commentsRef.push([{ type: 'comment', id: 1 }, { type: 'comment', id: 2 }]).then(function(comments) {
        commentsRef.value() === comments;
      });
      ```
       @method hasMany
      @param {String} name of the relationship
      @since 2.5.0
      @return {HasManyReference} reference for this relationship
    */
    hasMany: function hasMany(name) {
      return this._internalModel.referenceFor('hasMany', name);
    },

    /**
     Provides info about the model for debugging purposes
     by grouping the properties into more semantic groups.
      Meant to be used by debugging tools such as the Chrome Ember Extension.
      - Groups all attributes in "Attributes" group.
     - Groups all belongsTo relationships in "Belongs To" group.
     - Groups all hasMany relationships in "Has Many" group.
     - Groups all flags in "Flags" group.
     - Flags relationship CPs as expensive properties.
      @method _debugInfo
     @for Model
     @private
     */
    _debugInfo: function _debugInfo() {
      var attributes = ['id'];
      var relationships = {};
      var expensiveProperties = [];
      this.eachAttribute(function (name, meta) {
        return attributes.push(name);
      });
      var groups = [{
        name: 'Attributes',
        properties: attributes,
        expand: true
      }];
      this.eachRelationship(function (name, relationship) {
        var properties = relationships[relationship.kind];

        if (properties === undefined) {
          properties = relationships[relationship.kind] = [];
          groups.push({
            name: relationship.kind,
            properties: properties,
            expand: true
          });
        }

        properties.push(name);
        expensiveProperties.push(name);
      });
      groups.push({
        name: 'Flags',
        properties: ['isLoaded', 'hasDirtyAttributes', 'isSaving', 'isDeleted', 'isError', 'isNew', 'isValid']
      });
      return {
        propertyInfo: {
          // include all other mixins / properties (not just the grouped ones)
          includeOtherProperties: true,
          groups: groups,
          // don't pre-calculate unless cached
          expensiveProperties: expensiveProperties
        }
      };
    },
    notifyBelongsToChange: function notifyBelongsToChange(key) {
      this.notifyPropertyChange(key);
    },

    /**
     Given a callback, iterates over each of the relationships in the model,
     invoking the callback with the name of each relationship and its relationship
     descriptor.
       The callback method you provide should have the following signature (all
     parameters are optional):
      ```javascript
     function(name, descriptor);
     ```
      - `name` the name of the current property in the iteration
     - `descriptor` the meta object that describes this relationship
      The relationship descriptor argument is an object with the following properties.
      - **key** <span class="type">String</span> the name of this relationship on the Model
     - **kind** <span class="type">String</span> "hasMany" or "belongsTo"
     - **options** <span class="type">Object</span> the original options hash passed when the relationship was declared
     - **parentType** <span class="type">Model</span> the type of the Model that owns this relationship
     - **type** <span class="type">String</span> the type name of the related Model
      Note that in addition to a callback, you can also pass an optional target
     object that will be set as `this` on the context.
      Example
      ```app/serializers/application.js
     import JSONSerializer from '@ember-data/serializer/json';
      export default JSONSerializer.extend({
      serialize: function(record, options) {
        let json = {};
         record.eachRelationship(function(name, descriptor) {
          if (descriptor.kind === 'hasMany') {
            let serializedHasManyName = name.toUpperCase() + '_IDS';
            json[serializedHasManyName] = record.get(name).mapBy('id');
          }
        });
         return json;
      }
    });
     ```
      @method eachRelationship
     @param {Function} callback the callback to invoke
     @param {any} binding the value to which the callback's `this` should be bound
     */
    eachRelationship: function eachRelationship(callback, binding) {
      this.constructor.eachRelationship(callback, binding);
    },
    relationshipFor: function relationshipFor(name) {
      return Ember.get(this.constructor, 'relationshipsByName').get(name);
    },
    inverseFor: function inverseFor(key) {
      return this.constructor.inverseFor(key, this._internalModel.store);
    },
    notifyHasManyAdded: function notifyHasManyAdded(key) {
      //We need to notifyPropertyChange in the adding case because we need to make sure
      //we fetch the newly added record in case it is unloaded
      //TODO(Igor): Consider whether we could do this only if the record state is unloaded
      this.notifyPropertyChange(key);
    },
    eachAttribute: function eachAttribute(callback, binding) {
      this.constructor.eachAttribute(callback, binding);
    }
  });

  {
    /**
    Override the default event firing from Ember.Evented to
    also call methods with the given name.
     @method trigger
    @private
    @param {String} name
    */
    Model.reopen({
      trigger: function trigger(name) {
        {
          var fn = this[name];

          if (typeof fn === 'function') {
            var length = arguments.length;
            var args = new Array(length - 1);

            for (var i = 1; i < length; i++) {
              args[i - 1] = arguments[i];
            }

            fn.apply(this, args);
          }
        }

        var _hasEvent =  this.has(name);

        if (_hasEvent) {
          this._super.apply(this, arguments);
        }
      }
    });
  }

  {
    /**
    @property data
    @private
    @deprecated
    @type {Object}
    */
    Object.defineProperty(Model.prototype, 'data', {
      configurable: false,
      get: function get() {
        return Private.recordDataFor(this)._data;
      }
    });
  }

  {
    /**
      Use [JSONSerializer](JSONSerializer.html) to
      get the JSON representation of a record.
       `toJSON` takes an optional hash as a parameter, currently
      supported options are:
       - `includeId`: `true` if the record's ID should be included in the
        JSON representation.
       @method toJSON
      @param {Object} options
      @return {Object} A JSON representation of the object.
    */
    Model.reopen({
      toJSON: function toJSON(options) {

        var serializer = this._internalModel.store.serializerFor('-default');

        var snapshot = this._internalModel.createSnapshot();

        return serializer.serialize(snapshot, options);
      }
    });
  }

  var ID_DESCRIPTOR = {
    configurable: false,
    set: function set(id) {
      var normalizedId = Private.coerceId(id);

      if (normalizedId !== null) {
        this._internalModel.setId(normalizedId);
      }
    },
    get: function get() {

      Ember.get(this._internalModel, '_tag');
      return this._internalModel.id;
    }
  };
  Object.defineProperty(Model.prototype, 'id', ID_DESCRIPTOR);

  Model.reopenClass({
    isModel: true,

    /**
      Create should only ever be called by the store. To create an instance of a
      `Model` in a dirty state use `store.createRecord`.
      To create instances of `Model` in a clean state, use `store.push`
       @method create
      @private
      @static
    */

    /**
     Represents the model's class name as a string. This can be used to look up the model's class name through
     `Store`'s modelFor method.
      `modelName` is generated for you by Ember Data. It will be a lowercased, dasherized string.
     For example:
      ```javascript
     store.modelFor('post').modelName; // 'post'
     store.modelFor('blog-post').modelName; // 'blog-post'
     ```
      The most common place you'll want to access `modelName` is in your serializer's `payloadKeyFromModelName` method. For example, to change payload
     keys to underscore (instead of dasherized), you might use the following code:
      ```javascript
     import RESTSerializer from '@ember-data/serializer/rest';
     import { underscore } from '@ember/string';
      export default const PostSerializer = RESTSerializer.extend({
       payloadKeyFromModelName(modelName) {
         return underscore(modelName);
       }
     });
     ```
     @property modelName
     @type String
     @readonly
     @static
    */
    modelName: null,

    /*
     These class methods below provide relationship
     introspection abilities about relationships.
      A note about the computed properties contained here:
      **These properties are effectively sealed once called for the first time.**
     To avoid repeatedly doing expensive iteration over a model's fields, these
     values are computed once and then cached for the remainder of the runtime of
     your application.
      If your application needs to modify a class after its initial definition
     (for example, using `reopen()` to add additional attributes), make sure you
     do it before using your model with the store, which uses these properties
     extensively.
     */

    /**
     For a given relationship name, returns the model type of the relationship.
      For example, if you define a model like this:
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default Model.extend({
        comments: hasMany('comment')
      });
     ```
      Calling `store.modelFor('post').typeForRelationship('comments', store)` will return `Comment`.
      @method typeForRelationship
     @static
     @param {String} name the name of the relationship
     @param {store} store an instance of Store
     @return {Model} the type of the relationship, or undefined
     */
    typeForRelationship: function typeForRelationship(name, store) {
      var relationship = Ember.get(this, 'relationshipsByName').get(name);
      return relationship && store.modelFor(relationship.type);
    },
    inverseMap: Ember.computed(function () {
      return Object.create(null);
    }),

    /**
     Find the relationship which is the inverse of the one asked for.
      For example, if you define models like this:
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default Model.extend({
        comments: hasMany('message')
      });
     ```
      ```app/models/message.js
     import Model, { belongsTo } from '@ember-data/model';
      export default Model.extend({
        owner: belongsTo('post')
      });
     ```
      ``` js
     store.modelFor('post').inverseFor('comments', store) // { type: App.Message, name: 'owner', kind: 'belongsTo' }
     store.modelFor('message').inverseFor('owner', store) // { type: App.Post, name: 'comments', kind: 'hasMany' }
     ```
      @method inverseFor
     @static
     @param {String} name the name of the relationship
     @param {Store} store
     @return {Object} the inverse relationship, or null
     */
    inverseFor: function inverseFor(name, store) {
      var inverseMap = Ember.get(this, 'inverseMap');

      if (inverseMap[name]) {
        return inverseMap[name];
      } else {
        var inverse = this._findInverseFor(name, store);

        inverseMap[name] = inverse;
        return inverse;
      }
    },
    //Calculate the inverse, ignoring the cache
    _findInverseFor: function _findInverseFor(name, store) {
      var inverseType = this.typeForRelationship(name, store);

      if (!inverseType) {
        return null;
      }

      var propertyMeta = this.metaForProperty(name); //If inverse is manually specified to be null, like  `comments: hasMany('message', { inverse: null })`

      var options = propertyMeta.options;

      if (options.inverse === null) {
        return null;
      }

      var inverseName, inverseKind, inverse, inverseOptions; //If inverse is specified manually, return the inverse

      if (options.inverse) {
        inverseName = options.inverse;
        inverse = Ember.get(inverseType, 'relationshipsByName').get(inverseName);

        inverseKind = inverse.kind;
        inverseOptions = inverse.options;
      } else {
        //No inverse was specified manually, we need to use a heuristic to guess one
        if (propertyMeta.type === propertyMeta.parentModelName) ;

        var possibleRelationships = findPossibleInverses(this, inverseType, name);

        if (possibleRelationships.length === 0) {
          return null;
        }

        var filteredRelationships = possibleRelationships.filter(function (possibleRelationship) {
          var optionsForRelationship = inverseType.metaForProperty(possibleRelationship.name).options;
          return name === optionsForRelationship.inverse;
        });

        if (filteredRelationships.length === 1) {
          possibleRelationships = filteredRelationships;
        }
        inverseName = possibleRelationships[0].name;
        inverseKind = possibleRelationships[0].kind;
        inverseOptions = possibleRelationships[0].options;
      }
      return {
        type: inverseType,
        name: inverseName,
        kind: inverseKind,
        options: inverseOptions
      };
    },

    /**
     The model's relationships as a map, keyed on the type of the
     relationship. The value of each entry is an array containing a descriptor
     for each relationship with that type, describing the name of the relationship
     as well as the type.
      For example, given the following model definition:
      ```app/models/blog.js
     import Model, { belongsTo, hasMany } from '@ember-data/model';
      export default Model.extend({
        users: hasMany('user'),
        owner: belongsTo('user'),
        posts: hasMany('post')
      });
     ```
      This computed property would return a map describing these
     relationships, like this:
      ```javascript
     import Ember from 'ember';
     import Blog from 'app/models/blog';
     import User from 'app/models/user';
     import Post from 'app/models/post';
      let relationships = Ember.get(Blog, 'relationships');
     relationships.get('user');
     //=> [ { name: 'users', kind: 'hasMany' },
     //     { name: 'owner', kind: 'belongsTo' } ]
     relationships.get('post');
     //=> [ { name: 'posts', kind: 'hasMany' } ]
     ```
      @property relationships
     @static
     @type Map
     @readOnly
     */
    relationships: relationshipsDescriptor,

    /**
     A hash containing lists of the model's relationships, grouped
     by the relationship kind. For example, given a model with this
     definition:
      ```app/models/blog.js
     import Model, { belongsTo, hasMany } from '@ember-data/model';
      export default Model.extend({
        users: hasMany('user'),
        owner: belongsTo('user'),
         posts: hasMany('post')
      });
     ```
      This property would contain the following:
      ```javascript
     import Ember from 'ember';
     import Blog from 'app/models/blog';
      let relationshipNames = Ember.get(Blog, 'relationshipNames');
     relationshipNames.hasMany;
     //=> ['users', 'posts']
     relationshipNames.belongsTo;
     //=> ['owner']
     ```
      @property relationshipNames
     @static
     @type Object
     @readOnly
     */
    relationshipNames: Ember.computed(function () {
      var names = {
        hasMany: [],
        belongsTo: []
      };
      this.eachComputedProperty(function (name, meta) {
        if (meta.isRelationship) {
          names[meta.kind].push(name);
        }
      });
      return names;
    }),

    /**
     An array of types directly related to a model. Each type will be
     included once, regardless of the number of relationships it has with
     the model.
      For example, given a model with this definition:
      ```app/models/blog.js
     import Model, { belongsTo, hasMany } from '@ember-data/model';
      export default Model.extend({
        users: hasMany('user'),
        owner: belongsTo('user'),
         posts: hasMany('post')
      });
     ```
      This property would contain the following:
      ```javascript
     import Ember from 'ember';
     import Blog from 'app/models/blog';
      let relatedTypes = Ember.get(Blog, 'relatedTypes');
     //=> [ User, Post ]
     ```
      @property relatedTypes
     @static
     @type Ember.Array
     @readOnly
     */
    relatedTypes: relatedTypesDescriptor,

    /**
     A map whose keys are the relationships of a model and whose values are
     relationship descriptors.
      For example, given a model with this
     definition:
      ```app/models/blog.js
     import Model, { belongsTo, hasMany } from '@ember-data/model';
      export default Model.extend({
        users: hasMany('user'),
        owner: belongsTo('user'),
         posts: hasMany('post')
      });
     ```
      This property would contain the following:
      ```javascript
     import Ember from 'ember';
     import Blog from 'app/models/blog';
      let relationshipsByName = Ember.get(Blog, 'relationshipsByName');
     relationshipsByName.get('users');
     //=> { key: 'users', kind: 'hasMany', type: 'user', options: Object, isRelationship: true }
     relationshipsByName.get('owner');
     //=> { key: 'owner', kind: 'belongsTo', type: 'user', options: Object, isRelationship: true }
     ```
      @property relationshipsByName
     @static
     @type Map
     @readOnly
     */
    relationshipsByName: relationshipsByNameDescriptor,
    relationshipsObject: relationshipsObjectDescriptor,

    /**
     A map whose keys are the fields of the model and whose values are strings
     describing the kind of the field. A model's fields are the union of all of its
     attributes and relationships.
      For example:
      ```app/models/blog.js
     import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
      export default Model.extend({
        users: hasMany('user'),
        owner: belongsTo('user'),
         posts: hasMany('post'),
         title: attr('string')
      });
     ```
      ```js
     import Ember from 'ember';
     import Blog from 'app/models/blog';
      let fields = Ember.get(Blog, 'fields');
     fields.forEach(function(kind, field) {
        console.log(field, kind);
      });
      // prints:
     // users, hasMany
     // owner, belongsTo
     // posts, hasMany
     // title, attribute
     ```
      @property fields
     @static
     @type Map
     @readOnly
     */
    fields: Ember.computed(function () {
      var map = new Map();
      this.eachComputedProperty(function (name, meta) {
        if (meta.isRelationship) {
          map.set(name, meta.kind);
        } else if (meta.isAttribute) {
          map.set(name, 'attribute');
        }
      });
      return map;
    }).readOnly(),

    /**
     Given a callback, iterates over each of the relationships in the model,
     invoking the callback with the name of each relationship and its relationship
     descriptor.
      @method eachRelationship
     @static
     @param {Function} callback the callback to invoke
     @param {any} binding the value to which the callback's `this` should be bound
     */
    eachRelationship: function eachRelationship(callback, binding) {
      Ember.get(this, 'relationshipsByName').forEach(function (relationship, name) {
        callback.call(binding, name, relationship);
      });
    },

    /**
     Given a callback, iterates over each of the types related to a model,
     invoking the callback with the related type's class. Each type will be
     returned just once, regardless of how many different relationships it has
     with a model.
      @method eachRelatedType
     @static
     @param {Function} callback the callback to invoke
     @param {any} binding the value to which the callback's `this` should be bound
     */
    eachRelatedType: function eachRelatedType(callback, binding) {
      var relationshipTypes = Ember.get(this, 'relatedTypes');

      for (var i = 0; i < relationshipTypes.length; i++) {
        var type = relationshipTypes[i];
        callback.call(binding, type);
      }
    },
    determineRelationshipType: function determineRelationshipType(knownSide, store) {
      var knownKey = knownSide.key;
      var knownKind = knownSide.kind;
      var inverse = this.inverseFor(knownKey, store); // let key;

      var otherKind;

      if (!inverse) {
        return knownKind === 'belongsTo' ? 'oneToNone' : 'manyToNone';
      } // key = inverse.name;


      otherKind = inverse.kind;

      if (otherKind === 'belongsTo') {
        return knownKind === 'belongsTo' ? 'oneToOne' : 'manyToOne';
      } else {
        return knownKind === 'belongsTo' ? 'oneToMany' : 'manyToMany';
      }
    },

    /**
     A map whose keys are the attributes of the model (properties
     described by attr) and whose values are the meta object for the
     property.
      Example
      ```app/models/person.js
     import Model, { attr } from '@ember-data/model';
      export default Model.extend({
        firstName: attr('string'),
        lastName: attr('string'),
        birthday: attr('date')
      });
     ```
      ```javascript
     import Ember from 'ember';
     import Person from 'app/models/person';
      let attributes = Ember.get(Person, 'attributes')
      attributes.forEach(function(meta, name) {
        console.log(name, meta);
      });
      // prints:
     // firstName {type: "string", isAttribute: true, options: Object, parentType: function, name: "firstName"}
     // lastName {type: "string", isAttribute: true, options: Object, parentType: function, name: "lastName"}
     // birthday {type: "date", isAttribute: true, options: Object, parentType: function, name: "birthday"}
     ```
      @property attributes
     @static
     @type {Map}
     @readOnly
     */
    attributes: Ember.computed(function () {

      var map = new Map();
      this.eachComputedProperty(function (name, meta) {
        if (meta.isAttribute) {
          meta.name = name;
          map.set(name, meta);
        }
      });
      return map;
    }).readOnly(),

    /**
     A map whose keys are the attributes of the model (properties
     described by attr) and whose values are type of transformation
     applied to each attribute. This map does not include any
     attributes that do not have an transformation type.
      Example
      ```app/models/person.js
     import Model, { attr } from '@ember-data/model';
      export default Model.extend({
        firstName: attr(),
        lastName: attr('string'),
        birthday: attr('date')
      });
     ```
      ```javascript
     import Ember from 'ember';
     import Person from 'app/models/person';
      let transformedAttributes = Ember.get(Person, 'transformedAttributes')
      transformedAttributes.forEach(function(field, type) {
        console.log(field, type);
      });
      // prints:
     // lastName string
     // birthday date
     ```
      @property transformedAttributes
     @static
     @type {Map}
     @readOnly
     */
    transformedAttributes: Ember.computed(function () {
      var map = new Map();
      this.eachAttribute(function (key, meta) {
        if (meta.type) {
          map.set(key, meta.type);
        }
      });
      return map;
    }).readOnly(),

    /**
     Iterates through the attributes of the model, calling the passed function on each
     attribute.
      The callback method you provide should have the following signature (all
     parameters are optional):
      ```javascript
     function(name, meta);
     ```
      - `name` the name of the current property in the iteration
     - `meta` the meta object for the attribute property in the iteration
      Note that in addition to a callback, you can also pass an optional target
     object that will be set as `this` on the context.
      Example
      ```javascript
     import Model, { attr } from '@ember-data/model';
      let Person = Model.extend({
        firstName: attr('string'),
        lastName: attr('string'),
        birthday: attr('date')
      });
      Person.eachAttribute(function(name, meta) {
        console.log(name, meta);
      });
      // prints:
     // firstName {type: "string", isAttribute: true, options: Object, parentType: function, name: "firstName"}
     // lastName {type: "string", isAttribute: true, options: Object, parentType: function, name: "lastName"}
     // birthday {type: "date", isAttribute: true, options: Object, parentType: function, name: "birthday"}
     ```
      @method eachAttribute
     @param {Function} callback The callback to execute
     @param {Object} [binding] the value to which the callback's `this` should be bound
     @static
     */
    eachAttribute: function eachAttribute(callback, binding) {
      Ember.get(this, 'attributes').forEach(function (meta, name) {
        callback.call(binding, name, meta);
      });
    },

    /**
     Iterates through the transformedAttributes of the model, calling
     the passed function on each attribute. Note the callback will not be
     called for any attributes that do not have an transformation type.
      The callback method you provide should have the following signature (all
     parameters are optional):
      ```javascript
     function(name, type);
     ```
      - `name` the name of the current property in the iteration
     - `type` a string containing the name of the type of transformed
     applied to the attribute
      Note that in addition to a callback, you can also pass an optional target
     object that will be set as `this` on the context.
      Example
      ```javascript
     import Model, { attr } from '@ember-data/model';
      let Person = Model.extend({
        firstName: attr(),
        lastName: attr('string'),
        birthday: attr('date')
      });
      Person.eachTransformedAttribute(function(name, type) {
        console.log(name, type);
      });
      // prints:
     // lastName string
     // birthday date
     ```
      @method eachTransformedAttribute
     @param {Function} callback The callback to execute
     @param {Object} [binding] the value to which the callback's `this` should be bound
     @static
     */
    eachTransformedAttribute: function eachTransformedAttribute(callback, binding) {
      Ember.get(this, 'transformedAttributes').forEach(function (type, name) {
        callback.call(binding, name, type);
      });
    },

    /**
     Returns the name of the model class.
      @method toString
     @static
     */
    toString: function toString() {
      return "model:" + Ember.get(this, 'modelName');
    }
  });

  /**
    A `ManyArray` is a `MutableArray` that represents the contents of a has-many
    relationship.

    The `ManyArray` is instantiated lazily the first time the relationship is
    requested.

    ### Inverses

    Often, the relationships in Ember Data applications will have
    an inverse. For example, imagine the following models are
    defined:

    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';

    export default Model.extend({
      comments: hasMany('comment')
    });
    ```

    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';

    export default Model.extend({
      post: belongsTo('post')
    });
    ```

    If you created a new instance of `Post` and added
    a `Comment` record to its `comments` has-many
    relationship, you would expect the comment's `post`
    property to be set to the post that contained
    the has-many.

    We call the record to which a relationship belongs-to the
    relationship's _owner_.

    @class ManyArray
    @extends EmberObject
    @uses Ember.MutableArray, DeprecatedEvented
  */

  var manyArray = Ember.Object.extend(Ember.MutableArray, Private.DeprecatedEvented, {
    // here to make TS happy
    _inverseIsAsync: false,
    isLoaded: false,
    init: function init() {
      this._super.apply(this, arguments);
      /**
      The loading state of this array
       @property {Boolean} isLoaded
      */


      this.isLoaded = this.isLoaded || false;
      this.length = 0;
      /**
      Used for async `hasMany` arrays
      to keep track of when they will resolve.
       @property {Ember.RSVP.Promise} promise
      @private
      */

      this.promise = null;
      /**
      Metadata associated with the request for async hasMany relationships.
       Example
       Given that the server returns the following JSON payload when fetching a
      hasMany relationship:
       ```js
      {
        "comments": [{
          "id": 1,
          "comment": "This is the first comment",
        }, {
      // ...
        }],
         "meta": {
          "page": 1,
          "total": 5
        }
      }
      ```
       You can then access the metadata via the `meta` property:
       ```js
      post.get('comments').then(function(comments) {
        var meta = comments.get('meta');
       // meta.page => 1
      // meta.total => 5
      });
      ```
       @property {Object} meta
      @public
      */
      // TODO this is likely broken in our refactor

      this.meta = this.meta || null;
      /**
      `true` if the relationship is polymorphic, `false` otherwise.
       @property {Boolean} isPolymorphic
      @private
      */

      this.isPolymorphic = this.isPolymorphic || false;
      /**
      The relationship which manages this array.
       @property {ManyRelationship} relationship
      @private
      */

      this.currentState = [];
      this.flushCanonical(this.initialState, false); // we don't need this anymore, it just prevents garbage collection the records in the initialState

      this.initialState = undefined;
    },
    // TODO: if(DEBUG)
    anyUnloaded: function anyUnloaded() {
      // Use `filter[0]` as opposed to `find` because of IE11
      var unloaded = this.currentState.filter(function (im) {
        return im._isDematerializing || !im.isLoaded();
      })[0];
      return !!unloaded;
    },
    removeUnloadedInternalModel: function removeUnloadedInternalModel() {
      for (var i = 0; i < this.currentState.length; ++i) {
        var internalModel = this.currentState[i];
        var shouldRemove = void 0;

        {
          shouldRemove = internalModel._isDematerializing || !internalModel.isLoaded();
        }

        if (shouldRemove) {
          this.arrayContentWillChange(i, 1, 0);
          this.currentState.splice(i, 1);
          this.set('length', this.currentState.length);
          this.arrayContentDidChange(i, 1, 0);
          return true;
        }
      }

      return false;
    },
    objectAt: function objectAt(index) {
      // TODO we likely need to force flush here

      /*
      if (this.relationship._willUpdateManyArray) {
        this.relationship._flushPendingManyArrayUpdates();
      }
      */
      var internalModel = this.currentState[index];

      if (internalModel === undefined) {
        return;
      }

      return internalModel.getRecord();
    },
    flushCanonical: function flushCanonical(toSet, isInitialized) {
      if (isInitialized === void 0) {
        isInitialized = true;
      }

      // It’s possible the parent side of the relationship may have been unloaded by this point
      if (!Private._objectIsAlive(this)) {
        return;
      } // diff to find changes


      var diff = Private.diffArray(this.currentState, toSet);

      if (diff.firstChangeIndex !== null) {
        // it's null if no change found
        // we found a change
        this.arrayContentWillChange(diff.firstChangeIndex, diff.removedCount, diff.addedCount);
        this.set('length', toSet.length);
        this.currentState = toSet.slice();
        this.arrayContentDidChange(diff.firstChangeIndex, diff.removedCount, diff.addedCount);

        if (isInitialized && diff.addedCount > 0) {
          //notify only on additions
          //TODO only notify if unloaded
          this.internalModel.manyArrayRecordAdded(this.get('key'));
        }
      }
    },
    replace: function replace(idx, amt, objects) {
      var internalModels;

      if (amt > 0) {
        internalModels = this.currentState.slice(idx, idx + amt);
        this.get('recordData').removeFromHasMany(this.get('key'), internalModels.map(function (im) {
          return Private.recordDataFor(im);
        }));
      }

      if (objects) {
        this.get('recordData').addToHasMany(this.get('key'), objects.map(function (obj) {
          return Private.recordDataFor(obj);
        }), idx);
      }

      this.retrieveLatest();
    },
    // Ok this is kinda funky because if buggy we might lose positions, etc.
    // but current code is this way so shouldn't be too big of a problem
    retrieveLatest: function retrieveLatest() {
      var jsonApi = this.get('recordData').getHasMany(this.get('key')); // TODO this is odd, why should ManyArray ever tell itself to resync?

      var internalModels = this.store._getHasManyByJsonApiResource(jsonApi);

      if (jsonApi.meta) {
        this.set('meta', jsonApi.meta);
      }

      {
        if (jsonApi.links) {
          this.set('links', jsonApi.links);
        }
      }

      this.flushCanonical(internalModels, true);
    },

    /**
      Reloads all of the records in the manyArray. If the manyArray
      holds a relationship that was originally fetched using a links url
      Ember Data will revisit the original links url to repopulate the
      relationship.
       If the manyArray holds the result of a `store.query()` reload will
      re-run the original query.
       Example
       ```javascript
      var user = store.peekRecord('user', 1)
      user.login().then(function() {
        user.get('permissions').then(function(permissions) {
          return permissions.reload();
        });
      });
      ```
       @method reload
      @public
    */
    reload: function reload(options) {
      // TODO this is odd, we don't ask the store for anything else like this?
      return this.get('store').reloadManyArray(this, this.get('internalModel'), this.get('key'), options);
    },

    /**
      Saves all of the records in the `ManyArray`.
       Example
       ```javascript
      store.findRecord('inbox', 1).then(function(inbox) {
        inbox.get('messages').then(function(messages) {
          messages.forEach(function(message) {
            message.set('isRead', true);
          });
          messages.save()
        });
      });
      ```
       @method save
      @return {PromiseArray} promise
    */
    save: function save() {
      var manyArray = this;
      var promiseLabel = 'DS: ManyArray#save ' + Ember.get(this, 'type');
      var promise = Ember.RSVP.all(this.invoke('save'), promiseLabel).then(function () {
        return manyArray;
      }, null, 'DS: ManyArray#save return ManyArray');
      return Private.PromiseArray.create({
        promise: promise
      });
    },

    /**
      Create a child record within the owner
       @method createRecord
      @private
      @param {Object} hash
      @return {Model} record
    */
    createRecord: function createRecord(hash) {
      var store = Ember.get(this, 'store');
      var type = Ember.get(this, 'type');
      var record = store.createRecord(type.modelName, hash);
      this.pushObject(record);
      return record;
    }
  });

  /**
   @module @ember-data/model
   */

  /**
    A PromiseBelongsTo is a PromiseObject that also proxies certain method calls
    to the underlying belongsTo model.
    Right now we proxy:

      * `reload()`

    @class PromiseBelongsTo
    @extends PromiseObject
    @private
  */

  var PromiseBelongsTo = Private.PromiseObject.extend({
    // we don't proxy meta because we would need to proxy it to the relationship state container
    //  however, meta on relationships does not trigger change notifications.
    //  if you need relationship meta, you should do `record.belongsTo(relationshipName).meta()`
    meta: Ember.computed(function () {
    }),
    reload: function reload(options) {
      var _this = this;
      var _this$_belongsToState = this._belongsToState,
          key = _this$_belongsToState.key,
          store = _this$_belongsToState.store,
          originatingInternalModel = _this$_belongsToState.originatingInternalModel;
      return store.reloadBelongsTo(this, originatingInternalModel, key, options).then(function () {
        return _this;
      });
    }
  });

  /**
   @module @ember-data/model
   */

  /**
    A PromiseManyArray is a PromiseArray that also proxies certain method calls
    to the underlying manyArray.
    Right now we proxy:

      * `reload()`
      * `createRecord()`
      * `on()`
      * `one()`
      * `trigger()`
      * `off()`
      * `has()`

    @class PromiseManyArray
    @extends Ember.ArrayProxy
    @private
  */

  var PromiseManyArray = Private.PromiseArray.extend({
    links:  Ember.computed.reads('content.links') ,
    reload: function reload(options) {
      this.set('promise', this.get('content').reload(options));
      return this;
    },
    createRecord: proxyToContent('createRecord'),
    on: proxyToContent('on'),
    one: proxyToContent('one'),
    trigger: proxyToContent('trigger'),
    off: proxyToContent('off'),
    has: proxyToContent('has')
  });

  function proxyToContent(method) {
    return function () {
      var _Ember$get;

      return (_Ember$get = Ember.get(this, 'content'))[method].apply(_Ember$get, arguments);
    };
  }

  /*
      In case someone defined a relationship to a mixin, for example:
      ```
        let Comment = Model.extend({
          owner: belongsTo('commentable'. { polymorphic: true })
        });
        let Commentable = Ember.Mixin.create({
          comments: hasMany('comment')
        });
      ```
      we want to look up a Commentable class which has all the necessary
      relationship metadata. Thus, we look up the mixin and create a mock
      Model, so we can access the relationship CPs of the mixin (`comments`)
      in this case
    */
  function modelForMixin(store, normalizedModelName) {
    var owner = Ember.getOwner(store);
    var MaybeMixin = owner.factoryFor("mixin:" + normalizedModelName);
    var mixin = MaybeMixin && MaybeMixin.class;

    if (mixin) {
      var ModelForMixin = Model.extend(mixin);
      ModelForMixin.reopenClass({
        __isMixin: true,
        __mixin: mixin
      }); //Cache the class as a model

      owner.register('model:' + normalizedModelName, ModelForMixin);
    }

    return owner.factoryFor("model:" + normalizedModelName);
  }

  exports.Errors = Errors;
  exports.ManyArray = manyArray;
  exports.Model = Model;
  exports.PromiseBelongsTo = PromiseBelongsTo;
  exports.PromiseManyArray = PromiseManyArray;
  exports._modelForMixin = modelForMixin;
  exports.attr = attr$1;
  exports.belongsTo = belongsTo$1;
  exports.hasMany = hasMany$1;

  Object.defineProperty(exports, '__esModule', { value: true });

});
