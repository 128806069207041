define("ember-svg-jar/inlined/reaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;isolation:auto;mix-blend-mode:normal\" d=\"M2.5 2C1.677 2 1 2.677 1 3.5v8c0 .823.677 1.5 1.5 1.5H4v2.434L7.652 13H12.5c.823 0 1.5-.677 1.5-1.5v-8c0-.823-.677-1.5-1.5-1.5h-10zm0 1h10c.281 0 .5.219.5.5v8c0 .281-.219.5-.5.5H7.348L5 13.566V12H2.5a.493.493 0 01-.5-.5v-8c0-.281.219-.5.5-.5zm5 2l-.764 1.713-1.865.197 1.393 1.254L5.877 10 7.5 9.062 9.123 10l-.389-1.836L10.13 6.91l-1.865-.197L7.5 5z\" font-family=\"sans-serif\" font-weight=\"400\" overflow=\"visible\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});