define("ember-svg-jar/inlined/twitch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M25 42.105c-10.174 0-18.421-8.247-18.421-18.42C6.579 13.51 14.826 5.262 25 5.262s18.421 8.248 18.421 18.421c0 10.174-8.247 18.421-18.421 18.421z\"/><path fill=\"#5C4EA3\" d=\"M25 50C11.193 50 0 38.807 0 25S11.193 0 25 0s25 11.193 25 25-11.193 25-25 25zm11.842-35.526H17.763l-2.631 2.631v15.79h5.92v3.29h1.974l3.29-3.29h4.605l5.921-5.921v-12.5zm-11.184 15.79l-1.974 1.973h-1.316v-1.974h-3.29V16.447h15.79v9.21l-4.605 4.606h-4.605zM23.026 25h2.632v-5.263h-2.632V25zm5.263 0h2.632v-5.263H28.29V25z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});