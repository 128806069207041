define("ember-svg-jar/inlined/contv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M26.8 2.6a39.8 39.8 0 00-2.7 74c8.5 4 26.6 4 34.7 0 9-4.4 9-4 4.6-10.4a49.7 49.7 0 01-5.1-9.9c-1.7-5-3.1-6.9-4.2-5.2-5 7.6-22.6 5.7-27-3-7.8-15.3 7.2-29.3 23-21.5 3.3 1.6 6.3 2.8 6.4 2.6l2.1-5.7c1-2.9 3.3-7.5 5.3-10.3 2-2.8 3.3-5.3 3.1-5.5-7.4-6.6-29.5-9.4-40.2-5.1m57.4 1.2C45.4 23.2 57.8 77.7 101.4 79c44.4 1.5 59.5-55.5 20-75.3-6.4-3-7.8-3.3-18.8-3.3S90.4.7 84.2 3.8m65.2 36v39.5h24.4l.3-14.5.3-14.5 7.8 5.8c4.2 3.2 12.6 9.7 18.5 14.5l10.8 8.7H235V49.5l5-.4 5-.4 4 7.3 8 15.3c4.1 8 6 9.7 7.4 6 .4-1 6.5-12.4 13.5-25.3 15.9-29.3 15.9-27.3.2-26.9l-12.5.3-1.5 3.2-1.5 3.2-2-3.5-2.2-3.4h-45l.3-12.2.4-12.3H187l.3 14.3.3 14.3-6.6-5a956 956 0 01-18-14.3c-6.3-5.1-12-9.3-12.6-9.3-.7 0-1.1 13.9-1.1 39.5m-38-13.2c7.7 3.7 10 15 4.5 22-9 11.5-29.8 5.4-29.8-8.5C86 28 99.3 21 111.3 26.7m127.5 10.4l2.9 5.6h-13.5l.3 15.5.3 15.6h-11.1l.4-15.6.3-15.5h-10.7l.4-5.6.4-5.6H236l2.8 5.6m19.7 1.7c1.8 4 3.5 7.2 3.8 7.2.2 0 1.9-3.3 3.7-7.2l3.3-7.3h12.4l-2.7 4.8-10 18.4c-6 11.5-7.3 13.4-8 11.3-.4-1.4-4.6-9.7-9.4-18.5l-8.7-16h12.2l3.4 7.3\"/>",
    "attrs": {
      "viewBox": "0 0 290 80"
    }
  };
  _exports.default = _default;
});