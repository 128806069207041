define("ember-svg-jar/inlined/hulu-lg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M612.765 329.519h79.469V0h-79.469v329.519zM472.75 235.774c0 11.761-9.528 21.288-21.271 21.288h-46.222c-11.793 0-21.304-9.527-21.304-21.288V102.821H304.5v139.584c0 57.049 36.495 87.113 90.462 87.113h77.787v-.416c49.704 0 79.469-35.362 79.469-86.697V102.821H472.75v132.953zm448.197-132.953v132.953c0 11.761-9.527 21.288-21.271 21.288h-46.223c-11.776 0-21.287-9.527-21.287-21.288V102.821h-79.469v139.584c0 57.049 36.495 87.113 90.463 87.113h77.786v-.416c49.704 0 79.452-35.362 79.452-86.697V102.821h-79.451zm-763.692 0h-50.953c-17.889 0-26.851 4.822-26.851 4.822V0H0v329.519h79.452v-132.97c0-11.76 9.528-21.288 21.287-21.288h46.239c11.759 0 21.271 9.528 21.271 21.288v132.97h79.469V186.255c0-60.248-40.176-83.434-90.463-83.434z\" fill=\"#FFF\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "1001",
      "height": "330",
      "viewBox": "0 0 1001 330"
    }
  };
  _exports.default = _default;
});