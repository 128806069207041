define("ember-svg-jar/inlined/great", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><circle id=\"G\" cx=\"50\" cy=\"50\" r=\"50\"/><path id=\"H\" d=\"M.051 1.988s-.79 17.023 17.091 17.868c0 0 15.313-.055 16.505-18.098 0 0-7.891-1.11-16.52-1.11C8.5.648.052 1.988.052 1.988z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><use fill=\"#3E99D7\" stroke=\"#11537F\" stroke-width=\"4\" xlink:href=\"#G\" transform=\"translate(2 2)\"/><g transform=\"translate(6 4)\"><circle cx=\"46\" cy=\"46\" r=\"46\" fill=\"#43ABF1\"/><circle cx=\"22\" cy=\"56\" r=\"4\" fill=\"#11537F\"/><circle cx=\"72\" cy=\"56\" r=\"4\" fill=\"#11537F\"/></g><g transform=\"translate(36 68)\"><mask id=\"I\" fill=\"#fff\"><use xlink:href=\"#H\"/></mask><use fill=\"#11537F\" stroke=\"#11537F\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" xlink:href=\"#H\"/><path fill=\"#FFF\" d=\"M3.4 2.02C3.4.905 4.313 0 5.415 0h22.49c1.113 0 2.015.898 2.015 2.02v.097a1.9 1.9 0 01-2.016 1.915s-7.57-.42-11.244-.42c-3.674 0-11.244.42-11.244.42A1.892 1.892 0 013.4 2.117V2.02z\" mask=\"url(#I)\"/><ellipse cx=\"16.32\" cy=\"18.966\" fill=\"#FFA2A4\" mask=\"url(#I)\" rx=\"7.48\" ry=\"6.552\"/></g></g>",
    "attrs": {
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "104",
      "height": "104",
      "viewBox": "0 0 104 104"
    }
  };
  _exports.default = _default;
});