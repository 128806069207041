define("ember-svg-jar/inlined/meh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(2 2)\"><circle cx=\"50\" cy=\"50\" r=\"50\" fill=\"#DFB62E\" stroke=\"#7C1702\" stroke-width=\"4\"/><circle cx=\"50\" cy=\"48\" r=\"46\" fill=\"#EBC83C\"/></g><g transform=\"translate(24 56)\"><circle cx=\"4\" cy=\"4\" r=\"4\" fill=\"#7C1702\"/><circle cx=\"54\" cy=\"4\" r=\"4\" fill=\"#7C1702\"/><path stroke=\"#7C1702\" stroke-linecap=\"round\" stroke-width=\"4\" d=\"M15.042 22.068s12.156.173 27.61.173\"/></g></g>",
    "attrs": {
      "width": "104",
      "height": "104",
      "viewBox": "0 0 104 104"
    }
  };
  _exports.default = _default;
});