define("ember-svg-jar/inlined/gaming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans\" d=\"M23.906-.031a1 1 0 00-.125.031A1 1 0 0023 1s-.003.723-.469 1.469C22.065 3.215 21.233 4 19 4c-3.222 0-5.096 1.148-6.031 2.406C12.034 7.665 12.03 9 12.03 9a1 1 0 102 0s-.02-.665.531-1.406C15.114 6.852 16.223 6 19 6c2.767 0 4.435-1.215 5.219-2.469C25.003 2.277 25 1 25 1a1 1 0 00-1.094-1.031zM6.97 9.094c-2.748 0-6.75 4.952-6.75 11.187 0 1.955 1.487 4.657 3.125 4.657 3.276 0 3.896-5.032 9.656-5.032 5.76 0 6.38 5.032 9.656 5.032 1.638 0 3.125-2.702 3.125-4.657 0-6.235-4.002-11.187-6.75-11.187S15.748 12.063 13 12.063c-2.748 0-3.283-2.97-6.031-2.97zM18.75 12.75c.082-.017.163 0 .25 0 .697 0 1.25.551 1.25 1.25A1.24 1.24 0 0119 15.25c-.697 0-1.25-.552-1.25-1.25 0-.61.425-1.132 1-1.25zM6 13h2v2h2v2H8v2H6v-2H4v-2h2v-2zm10.75 1.75c.082-.017.163 0 .25 0 .699 0 1.25.552 1.25 1.25A1.24 1.24 0 0117 17.25c-.698 0-1.25-.553-1.25-1.25 0-.61.424-1.132 1-1.25zm4 0c.082-.017.163 0 .25 0 .699 0 1.25.552 1.25 1.25A1.24 1.24 0 0121 17.25 1.24 1.24 0 0119.75 16c0-.61.424-1.132 1-1.25zm-2 2c.082-.017.163 0 .25 0 .698 0 1.25.551 1.25 1.25A1.24 1.24 0 0119 19.25 1.24 1.24 0 0117.75 18c0-.612.424-1.132 1-1.25z\" color=\"#000\" font-family=\"Bitstream Vera Sans\" overflow=\"visible\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});