define("ember-svg-jar/inlined/unreleased", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2 0v1h-.5C.677 1 0 1.677 0 2.5v9c0 .823.677 1.5 1.5 1.5h10c.823 0 1.5-.677 1.5-1.5v-9c0-.823-.677-1.5-1.5-1.5H11V0h-1v1H3V0H2zm-.5 2H2v1h1V2h7v1h1V2h.5c.281 0 .5.219.5.5V4H1V2.5c0-.281.219-.5.5-.5zM1 5h11v6.5c0 .281-.219.5-.5.5h-10a.493.493 0 01-.5-.5V5zm3 1v1h1V6H4zm2 0v1h1V6H6zm2 0v1h1V6H8zm2 0v1h1V6h-1zM2 8v1h1V8H2zm2 0v1h1V8H4zm2 0v1h1V8H6zm2 0v1h1V8H8zm2 0v1h1V8h-1zm-8 2v1h1v-1H2zm2 0v1h1v-1H4zm2 0v1h1v-1H6z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});