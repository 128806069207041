define("ember-svg-jar/inlined/info-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.5 0C2.464 0 0 2.2 0 4.952a4.703 4.703 0 001.61 3.485l-.143 2.26a.29.29 0 00.447.256L3.839 9.67c.54.155 1.1.234 1.661.235 3.036 0 5.5-2.2 5.5-4.952C11 2.201 8.536 0 5.5 0zm-.242 8.21a.803.803 0 11.008-1.607.803.803 0 01-.008 1.606zm1.195-3.214c-.322.257-.491.404-.491.657a.69.69 0 11-1.379 0c0-.884.55-1.332.954-1.658.253-.206.403-.338.403-.463 0-.124 0-.432-.682-.432a1.466 1.466 0 00-.733.187.733.733 0 01-.367.113.66.66 0 01-.66-.66.675.675 0 01.367-.59 2.647 2.647 0 011.466-.408c1.207 0 2.017.705 2.017 1.754A1.834 1.834 0 016.453 5v-.004z\" fill=\"#332432\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "11",
      "height": "11",
      "viewBox": "0 0 11 11"
    }
  };
  _exports.default = _default;
});