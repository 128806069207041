define("ember-svg-jar/inlined/youtube2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#CE1312\" d=\"M100 186c0-13.807 11.025-25 24.626-25 13.6 0 24.625 11.193 24.625 25s-11.025 25-24.625 25S100 199.807 100 186z\"/><path fill=\"#FFF\" d=\"M137.443 180.968s-.257-1.878-1.043-2.705c-.998-1.086-2.116-1.09-2.628-1.154-3.67-.276-9.176-.276-9.176-.276h-.012s-5.505 0-9.176.276c-.513.063-1.63.068-2.628 1.154-.787.827-1.042 2.705-1.042 2.705s-.263 2.205-.263 4.41v2.068c0 2.205.263 4.41.263 4.41s.255 1.878 1.042 2.705c.998 1.086 2.309 1.052 2.892 1.165 2.098.21 8.918.274 8.918.274s5.512-.009 9.182-.284c.512-.064 1.63-.07 2.628-1.155.786-.827 1.043-2.705 1.043-2.705s.262-2.205.262-4.41v-2.068c0-2.205-.262-4.41-.262-4.41z\"/><path fill=\"#CE1312\" d=\"M121.882 189.951l-.001-7.656 7.087 3.841z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "100 161 50 50"
    }
  };
  _exports.default = _default;
});