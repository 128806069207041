define("ember-svg-jar/inlined/discord", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#7289DA\" d=\"M50 25c0 13.807-11.193 25-25 25S0 38.807 0 25 11.193 0 25 0s25 11.193 25 25z\"/><path fill=\"#FFF\" d=\"M32.05 35.428s-.892-1.066-1.636-2.007c3.245-.916 4.483-2.947 4.483-2.947a14.097 14.097 0 01-2.847 1.46A16.23 16.23 0 0128.458 33c-2.378.447-4.557.322-6.415-.026a20.79 20.79 0 01-3.64-1.063 14.513 14.513 0 01-1.808-.843c-.075-.05-.149-.075-.224-.123a.383.383 0 01-.099-.075 7.804 7.804 0 01-.693-.421s1.19 1.983 4.334 2.923c-.743.942-1.658 2.057-1.658 2.057-5.473-.174-7.555-3.763-7.555-3.763 0-7.982 3.566-14.445 3.566-14.445 3.566-2.675 6.96-2.6 6.96-2.6l.248.3c-4.458 1.287-6.513 3.243-6.513 3.243s.544-.297 1.46-.718c2.65-1.166 4.755-1.487 5.622-1.562.15-.026.273-.05.421-.05 1.66-.216 3.34-.232 5.003-.048 2.355.272 4.882.965 7.455 2.377 0 0-1.955-1.858-6.165-3.145l.347-.397s3.392-.075 6.96 2.6c0 0 3.566 6.463 3.566 14.44 0 0-2.105 3.592-7.579 3.763l-.001.004z\"/><path fill=\"#7289DA\" d=\"M20.533 23.86c-1.412 0-2.526 1.237-2.526 2.75 0 1.511 1.14 2.75 2.526 2.75 1.412 0 2.526-1.239 2.526-2.75.027-1.513-1.114-2.75-2.526-2.75zm9.04 0c-1.412 0-2.527 1.237-2.527 2.75 0 1.511 1.14 2.75 2.526 2.75 1.412 0 2.527-1.239 2.527-2.75 0-1.513-1.115-2.75-2.527-2.75z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});