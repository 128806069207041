define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * NOTE: this is a generated file that is produced at build time
   * during `treeForAddon`.
   * @private
   * @hide
   */
  var _default = [];
  _exports.default = _default;
});