define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M6 4.586l4.24-4.24a1 1 0 111.416 1.413L7.413 6l4.24 4.24a1 1 0 11-1.413 1.416L6 7.413l-4.24 4.24A1 1 0 11.344 10.24L4.587 6 .347 1.76A1 1 0 111.757.343L6 4.587z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12"
    }
  };
  _exports.default = _default;
});