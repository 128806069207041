define("ember-svg-jar/inlined/misc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 3c-1.159 0-2 .841-2 2v16c0 1.159.841 2 2 2h16c1.159 0 2-.841 2-2V5c0-1.159-.841-2-2-2H5zm24 0c-.522 0-1.06.185-1.438.563C27.186 3.94 27 4.478 27 5v16c0 .522.185 1.06.563 1.438.377.377.915.562 1.437.562h16c.522 0 1.06-.185 1.438-.563.377-.377.562-.915.562-1.437V5c0-.522-.185-1.06-.563-1.438C46.06 3.186 45.523 3 45 3H29zm0 2h16v16H29V5zM5 27c-1.159 0-2 .841-2 2v16c0 1.159.841 2 2 2h16c1.159 0 2-.841 2-2V29c0-1.159-.841-2-2-2H5zm24 0a2.13 2.13 0 00-.406.031c-.144.026-.27.07-.407.125-.052.021-.105.037-.156.063a1.84 1.84 0 00-.718.625c-.005.007.004.024 0 .031-.033.051-.066.101-.094.156-.026.05-.042.104-.063.157-.054.135-.099.262-.125.406-.023.13-.031.275-.031.406v16c0 .13.008.276.031.406.026.144.07.27.125.407.044.109.094.212.157.312.14.222.311.418.53.563.008.004.025-.005.032 0 .051.032.101.065.156.093.05.026.104.042.157.063.135.054.262.099.406.125.13.023.275.031.406.031h16c.145 0 .272-.006.406-.031.144-.026.27-.07.407-.125.053-.022.105-.037.156-.063a1.84 1.84 0 00.718-.656c.033-.052.067-.101.094-.156.026-.051.041-.103.063-.157.054-.135.099-.262.125-.406A2.13 2.13 0 0047 45V29a2.13 2.13 0 00-.031-.406 2.016 2.016 0 00-.125-.407c-.022-.053-.037-.105-.063-.156-.031-.062-.056-.129-.093-.187a1.83 1.83 0 00-.563-.532c-.052-.032-.101-.066-.156-.093-.051-.026-.103-.041-.157-.063a2.016 2.016 0 00-.406-.125A2.13 2.13 0 0045 27H29zm2.563 2h2.875L29 34.438v-2.876L31.563 29zm6 0h2.874L29 40.438v-2.876L37.563 29zm6 0H45v1.438L30.437 45H29v-1.438L43.563 29zM9 31a2 2 0 11-.001 4.001A2 2 0 019 31zm8 0a2 2 0 11-.001 4.001A2 2 0 0117 31zm28 2.563v2.874L36.437 45h-2.874L45 33.562zM9 39a2 2 0 11-.001 4.001A2 2 0 019 39zm8 0a2 2 0 11-.001 4.001A2 2 0 0117 39zm28 .563v2.874L42.437 45h-2.874L45 39.562z\"/>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});