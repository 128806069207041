define("ember-svg-jar/inlined/creative-arts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans\" d=\"M13.563 2.094a2.09 2.09 0 00-.407.094v-.063l-.125.031-9.594 2.469a1 1 0 00-.093.031C2.438 5.018 1.83 5.997 2.03 7a1 1 0 000 .063l1.813 6.78a1 1 0 000 .032c.57 1.94 2.11 3.122 3.656 3.813.474.766 1.073 1.444 1.656 2a15.109 15.109 0 002.969 2.187 1 1 0 00.625.125s.44-.078 1.094-.25a16.142 16.142 0 002.437-.844c.685-.304 1.427-.689 2.094-1.187.222-.166.42-.34.625-.532.819-.766 1.47-1.752 1.688-3l1.28-7a1 1 0 00.032-.03c.186-1.116-.566-2.139-1.594-2.345a1 1 0 00-.031 0l-4.094-.75-.687-2.468c-.005-.026-.026-.038-.031-.063-.13-.538-.484-.929-.907-1.187a1.786 1.786 0 00-1.094-.25zM8.875 5.28v.032c-.48.301-.86.765-1.031 1.28a1 1 0 00-.032.126L7.47 8.78c-.091 0-.191.009-.282.031-.8.2-1.187 1.376-1.187 1.376s.522.46 1.125.593l-.5 2.938a4.906 4.906 0 00-.063.937c-.343-.373-.622-.797-.78-1.312l-1.813-6.75c0 .003-.001-.025.094-.063l4.812-1.25zM9.844 7c.026-.025.051-.007.094 0L15 7.906 13.5 16l-3-.5.094.188c.393 1.179 1.358 2.092 2.531 2.312l-.344 1.906c-.248-.141-1.16-.649-2.25-1.687-1.074-1.024-1.942-2.287-1.969-3.594a2.898 2.898 0 01.032-.563L9.75 7.22c.042-.126.068-.194.094-.219zm1.75 3.469c-.708.038-1.406.719-1.406.719S10.7 12.4 11.5 12.5c.8.2 1.688-.688 1.688-.688s-.482-1.212-1.282-1.312a1.086 1.086 0 00-.312-.031zm6.094 1.094c.1-.006.212.006.312.03.733.092 1.206 1.082 1.281 1.25v.032l-.031.063c-.134.113-.925.747-1.656.656-.8-.1-1.282-1.281-1.282-1.281s.667-.712 1.375-.75z\" color=\"#000\" font-family=\"Bitstream Vera Sans\" overflow=\"visible\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});