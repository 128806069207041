define("ember-svg-jar/inlined/tubitv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M34.864 40.63a1.134 1.134 0 00-1.44-.48c-6.007 2.626-12.735-1.775-12.736-8.33v-7.961c0-.626.508-1.134 1.134-1.134h11.362c.627 0 1.134-.508 1.134-1.134v-6.805c0-.626-.507-1.134-1.134-1.134H21.822a1.134 1.134 0 01-1.134-1.134V1.134C20.688.508 20.18 0 19.554 0h-7.96v31.82c.022 13.433 14.1 22.208 26.17 16.312.57-.274.805-.96.524-1.526z\"/>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});