define("ember-star-rating/templates/components/star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QSOwSVHK",
    "block": "{\"symbols\":[\"star\",\"index\"],\"statements\":[[4,\"each\",[[25,[\"stars\"]]],null,{\"statements\":[[0,\"  \"],[7,\"svg\"],[12,\"width\",[23,\"width\"]],[12,\"height\",[23,\"height\"]],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12,\"viewBox\",[23,\"viewBox\"]],[9],[0,\"\\n    \"],[7,\"defs\"],[9],[0,\"\\n      \"],[7,\"linearGradient\"],[12,\"id\",[30,[[23,\"elementId\"],\"-star-\",[24,2,[]]]]],[11,\"x1\",\"0%\"],[11,\"y1\",\"0%\"],[11,\"x2\",\"100%\"],[11,\"y2\",\"0%\"],[9],[0,\"\\n        \"],[7,\"stop\"],[11,\"class\",\"star-rating-filled\"],[11,\"offset\",\"0%\"],[12,\"stop-color\",[23,\"fillColor\"]],[9],[10],[0,\"\\n        \"],[7,\"stop\"],[11,\"class\",\"star-rating-base\"],[11,\"offset\",\"0%\"],[12,\"stop-color\",[23,\"baseColor\"]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"path\"],[12,\"fill\",[30,[\"url(#\",[23,\"elementId\"],\"-star-\",[24,2,[]],\")\"]]],[12,\"d\",[23,\"svgPath\"]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-star-rating/templates/components/star-rating.hbs"
    }
  });

  _exports.default = _default;
});