define("ember-svg-jar/inlined/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#55ACEE\" d=\"M400 26c0-13.807 11.193-25 25-25s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z\"/><path fill=\"#FFF\" d=\"M424.251 21.32l.053.865-.875-.106c-3.182-.406-5.963-1.783-8.323-4.095l-1.155-1.148-.297.848c-.63 1.889-.227 3.883 1.084 5.225.7.742.542.848-.664.406-.42-.141-.787-.247-.822-.194-.122.124.297 1.73.63 2.366.454.882 1.38 1.747 2.395 2.26l.857.405-1.014.018c-.98 0-1.014.018-.91.389.35 1.147 1.732 2.365 3.27 2.895l1.085.37-.945.566a9.845 9.845 0 01-4.686 1.306c-.787.018-1.434.088-1.434.141 0 .177 2.133 1.165 3.375 1.554 3.725 1.147 8.149.653 11.471-1.307 2.361-1.394 4.722-4.166 5.824-6.85.594-1.43 1.189-4.042 1.189-5.296 0-.812.052-.918 1.031-1.889.578-.565 1.12-1.183 1.225-1.36.174-.335.157-.335-.735-.034-1.486.53-1.696.459-.962-.336.542-.565 1.19-1.589 1.19-1.889 0-.053-.263.035-.56.194-.315.177-1.014.442-1.539.6l-.944.3-.857-.582c-.472-.318-1.137-.67-1.487-.777-.891-.247-2.255-.212-3.06.07-2.186.795-3.567 2.843-3.41 5.085z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "400 1 50 50"
    }
  };
  _exports.default = _default;
});