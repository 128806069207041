define("ember-svg-jar/inlined/globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M46.353 35.936h-9.064C38 32.91 38.422 29.558 38.488 26h10.487a23.86 23.86 0 01-2.622 9.936zM1.025 26h10.487c.067 3.558.488 6.909 1.199 9.936H3.647A23.83 23.83 0 011.025 26zm2.622-11.936h9.064C12 17.091 11.58 20.443 11.513 24H1.025a23.826 23.826 0 012.622-9.936zM26 12.021V1.101c3.558.675 6.708 4.902 8.608 10.921H26zm9.187 2.043c.764 3.019 1.228 6.391 1.301 9.936H26v-9.936h9.187zM24 1.101v10.921h-8.608c1.9-6.019 5.05-10.246 8.608-10.921zm0 12.963V24H13.513c.074-3.544.537-6.917 1.3-9.936H24zM13.513 26H24v9.936h-9.187c-.763-3.019-1.227-6.391-1.3-9.936zM24 37.979V48.9c-3.558-.676-6.708-4.902-8.608-10.921H24zm2 10.92v-10.92h8.608c-1.9 6.018-5.05 10.245-8.608 10.92zm0-12.963V26h10.487c-.073 3.545-.537 6.917-1.301 9.936H26zM38.487 24c-.066-3.557-.487-6.909-1.199-9.936h9.064A23.826 23.826 0 0148.974 24H38.487zm6.685-11.979H36.75c-1.285-4.378-3.21-7.925-5.549-10.205 5.824 1.56 10.778 5.262 13.971 10.205zM18.799 1.817c-2.339 2.28-4.264 5.827-5.548 10.205H4.828C8.02 7.078 12.974 3.376 18.799 1.817zM4.828 37.979h8.422c1.285 4.378 3.21 7.925 5.549 10.205-5.824-1.56-10.779-5.262-13.971-10.205zm26.373 10.205c2.339-2.28 4.264-5.827 5.549-10.205h8.422c-3.193 4.943-8.147 8.645-13.971 10.205z\"/>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});