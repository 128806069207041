define("ember-svg-jar/inlined/website", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FF005C\" d=\"M.92 25c0-13.807 10.924-25 24.4-25 13.477 0 24.402 11.193 24.402 25S38.799 50 25.32 50C11.846 50 .92 38.807.92 25z\"/><path fill=\"#FFF\" d=\"M25.212 29.862l-3.876 3.974c-.92.947-2.526.947-3.446 0l-.862-.882a2.52 2.52 0 01-.713-1.767c0-.669.253-1.296.713-1.769l5.598-5.746a2.396 2.396 0 013.445 0l.858.886a1.197 1.197 0 001.724 0 1.274 1.274 0 000-1.769l-.858-.884a4.789 4.789 0 00-6.892 0l-5.598 5.745a5.03 5.03 0 00-1.426 3.534 5.04 5.04 0 001.426 3.537l.862.882a4.78 4.78 0 003.445 1.465c1.302 0 2.525-.52 3.446-1.464l3.875-3.979a1.27 1.27 0 00.368-1.233c-.11-.449-.451-.8-.89-.91-.437-.111-.899.035-1.2.38zm10.765-14.586l-.862-.884a4.789 4.789 0 00-6.89 0L24.35 18.37a1.273 1.273 0 00-.316 1.207c.112.432.44.77.86.885.421.116.87-.007 1.178-.323l3.875-3.976a2.396 2.396 0 013.445 0l.86.882a2.557 2.557 0 010 3.54l-5.598 5.743a2.384 2.384 0 01-3.441 0 1.196 1.196 0 00-1.721 0 1.273 1.273 0 00-.004 1.765 4.767 4.767 0 003.447 1.468 4.776 4.776 0 003.444-1.466l5.6-5.744c1.9-1.954 1.9-5.118 0-7.072l-.002-.003z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});