define("ember-apollo-client/-private/mixins/route-query-manager", ["exports", "ember-apollo-client/-private/mixins/base-query-manager"], function (_exports, _baseQueryManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_baseQueryManager.default, {
    beforeModel: function beforeModel() {
      this.get('apollo').markSubscriptionsStale();
      return this._super.apply(this, arguments);
    },
    resetController: function resetController(_controller, isExiting) {
      this._super.apply(this, arguments);

      this.get('apollo').unsubscribeAll(!isExiting);
    },
    willDestroy: function willDestroy() {
      var apollo = this.get('apollo');

      if (apollo.unsubscribeAll) {
        this.get('apollo').unsubscribeAll(false);
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});