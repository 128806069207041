define("ember-svg-jar/inlined/music-movies-tv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.438 2.156l-6.344 4.281-3.406-3.156L6.313 4.72 8.78 7H4c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2h-6.156l4.719-3.156-1.125-1.688zM8.25 9c.52-.013 1.1 0 1.75 0 5.3 0 6 0 6 5.5s-.8 5.5-6 5.5-6-.1-6-5.5c0-4.725.613-5.407 4.25-5.5zM19 13c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm0 4c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1z\"/>",
    "attrs": {
      "version": "1",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});