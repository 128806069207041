define("ember-apollo-client/index", ["exports", "ember-apollo-client/-private/mixins/component-query-manager", "ember-apollo-client/-private/mixins/object-query-manager", "ember-apollo-client/-private/mixins/route-query-manager"], function (_exports, _componentQueryManager, _objectQueryManager, _routeQueryManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getObservable = getObservable;
  Object.defineProperty(_exports, "ComponentQueryManager", {
    enumerable: true,
    get: function get() {
      return _componentQueryManager.default;
    }
  });
  Object.defineProperty(_exports, "ObjectQueryManager", {
    enumerable: true,
    get: function get() {
      return _objectQueryManager.default;
    }
  });
  Object.defineProperty(_exports, "RouteQueryManager", {
    enumerable: true,
    get: function get() {
      return _routeQueryManager.default;
    }
  });
  _exports.apolloObservableKey = void 0;

  function getObservable(queryResult) {
    return queryResult._apolloObservable;
  }

  var apolloObservableKey = '_apolloObservable';
  _exports.apolloObservableKey = apolloObservableKey;
});