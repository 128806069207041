define("ember-svg-jar/inlined/playerme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#262A34\" rx=\"25\"/><path fill=\"#694EA0\" d=\"M25 9.005c-6.595 0-11.942 5.514-11.942 12.315v.505c.335 8.939 9.393 20.17 9.393 20.17v-14.84c-2.724-1.26-4.19-4.343-3.49-7.336.7-2.993 3.367-5.048 6.348-4.89 2.982.157 5.432 2.483 5.83 5.534.4 3.05-1.364 5.962-4.199 6.93v6.08c6.132-1.046 10.462-6.768 9.955-13.156C36.39 13.93 31.215 9.01 25 9.005z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});