define("kitsu-shared/templates/components/infinite-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ntCzD/4P",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isTop\"]]],null,{\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"isLoading\"]],[25,[\"showLoader\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"w-100 text-xs-center m-t-2 m-b-2\"],[9],[0,\"\\n      \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[[25,[\"loaderSize\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"  \"],[7,\"div\"],[12,\"id\",[23,\"viewportDivId\"]],[11,\"style\",\"width: 100%; height: 1px; background-color: transparent\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[15,1,[[25,[\"isLoading\"]]]],[0,\"\\n\\n\"],[4,\"unless\",[[25,[\"isTop\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[23,\"viewportDivId\"]],[11,\"style\",\"width: 100%; height: 1px; background-color: transparent\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"isLoading\"]],[25,[\"showLoader\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"w-100 text-xs-center m-t-2 m-b-2\"],[9],[0,\"\\n      \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[[25,[\"loaderSize\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "kitsu-shared/templates/components/infinite-pagination.hbs"
    }
  });

  _exports.default = _default;
});