define("ember-svg-jar/inlined/steam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#25272B\" rx=\"25\"/><path fill=\"#FFF\" d=\"M35.36 23.237c0 1.04-.77 1.881-1.72 1.881-.947 0-1.718-.842-1.718-1.881 0-1.04.771-1.882 1.72-1.882.947 0 1.72.842 1.72 1.882h-.001zm-1.728-3.5c-1.764 0-3.194 1.555-3.21 3.479l-2 3.134a2.204 2.204 0 00-1.47.355l-9.057-3.987c-.237-1.173-1.198-2.059-2.342-2.059-1.319 0-2.395 1.182-2.395 2.627 0 1.443 1.076 2.62 2.395 2.62.447 0 .865-.134 1.223-.368l9.058 3.978c.234 1.179 1.195 2.066 2.342 2.066 1.24 0 2.263-1.04 2.382-2.365l3.075-2.46c1.772 0 3.21-1.569 3.21-3.507 0-1.938-1.44-3.513-3.211-3.513zm0 1.158c1.188 0 2.152 1.052 2.152 2.352 0 1.3-.964 2.352-2.151 2.352-1.187 0-2.151-1.053-2.151-2.352 0-1.298.964-2.355 2.152-2.355l-.002.003zm-18.08.458c.68 0 1.264.414 1.56 1.023l-.876-.381v.002c-.707-.276-1.491.1-1.774.869-.282.767.043 1.635.727 1.97v.003l.744.325c-.122.03-.25.048-.382.048-.973 0-1.76-.858-1.76-1.926 0-1.069.785-1.932 1.763-1.932l-.001-.001zm12.623 5.677c.976 0 1.763.86 1.763 1.928s-.787 1.926-1.763 1.926c-.679 0-1.266-.414-1.56-1.026l.868.381c.72.316 1.537-.061 1.826-.848.29-.786-.062-1.677-.78-1.992l-.734-.321c.122-.032.25-.05.381-.05l-.001.002z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});