define("ember-svg-jar/inlined/kickstarter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"nonzero\" transform=\"translate(724 224)\" fill=\"none\"><rect width=\"50\" height=\"50\" fill=\"#0F2105\" rx=\"25\"/><path fill=\"#56B368\" d=\"M24.07 21.649l3.862-5.594c.729-1.055 1.67-1.581 2.827-1.581.942 0 1.757.334 2.448 1.004.688.668 1.032 1.468 1.032 2.4 0 .69-.181 1.298-.547 1.825l-3.481 5.061 4.256 5.397c.426.536.638 1.165.638 1.885 0 .953-.333 1.77-1.002 2.454a3.275 3.275 0 01-2.432 1.026c-1.045 0-1.84-.34-2.387-1.018l-5.214-6.505v3.586c0 1.024-.178 1.82-.532 2.387-.649 1.033-1.592 1.55-2.827 1.55-1.125 0-1.997-.38-2.615-1.14-.578-.7-.866-1.627-.866-2.78V18.302c0-1.094.294-1.996.882-2.706.618-.75 1.468-1.125 2.552-1.125 1.035 0 1.895.375 2.585 1.125.385.416.629.836.73 1.262.06.263.092.754.092 1.474v3.314\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "724 224 50 50"
    }
  };
  _exports.default = _default;
});