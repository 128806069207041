define("kitsu-shared/templates/components/read-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WmflCW/b",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isTruncated\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,\"truncatedText\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1,[[29,\"hash\",null,[[\"isTruncated\",\"wasTruncated\",\"toggleVisibility\"],[[25,[\"isTruncated\"]],[25,[\"wasTruncated\"]],[29,\"action\",[[24,0,[]],\"toggleVisibility\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"isTruncated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"d-inline-block\"],[11,\"href\",\"#\"],[9],[1,[23,\"expandText\"],false],[3,\"action\",[[24,0,[]],\"toggleVisibility\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"wasTruncated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"d-inline-block\"],[11,\"href\",\"#\"],[9],[1,[23,\"collapseText\"],false],[3,\"action\",[[24,0,[]],\"toggleVisibility\"]],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "kitsu-shared/templates/components/read-more.hbs"
    }
  });

  _exports.default = _default;
});