define("kitsu-shared/components/read-more", ["exports", "kitsu-shared/templates/components/read-more", "text-clipper"], function (_exports, _readMore, _textClipper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ReadMoreComponent = Ember.Component.extend({
    layout: _readMore.default,
    isHTML: false,
    maxLines: Infinity,
    isTruncated: false,
    wasTruncated: false,
    expandText: 'read more',
    collapseText: 'read less',
    truncatedText: Ember.computed('text', 'length', 'isHTML', 'maxLines', function () {
      var options = {
        html: this.get('isHTML'),
        maxLines: this.get('maxLines')
      };
      var text = this.get('text');

      if (!text) {
        return null;
      }

      var string = Ember.String.isHTMLSafe(text) ? text.string : text;
      return Ember.String.htmlSafe((0, _textClipper.default)(string, this.get('length'), options));
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      var text = Ember.get(this, 'text');
      var textLength = Ember.String.isHTMLSafe(text) ? text.string.length : text.length;
      var truncated = this.get('truncatedText');

      if (truncated && truncated.string.length < textLength) {
        Ember.set(this, 'isTruncated', true);
        Ember.set(this, 'wasTruncated', true);
      }
    },
    actions: {
      toggleVisibility: function toggleVisibility() {
        this.toggleProperty('isTruncated');
      }
    }
  });
  ReadMoreComponent.reopenClass({
    positionalParams: ['text', 'length']
  });
  var _default = ReadMoreComponent;
  _exports.default = _default;
});