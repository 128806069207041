define("ember-svg-jar/inlined/google", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#DC4E41\" d=\"M300 106c0-13.807 11.025-25 24.626-25 13.6 0 24.625 11.193 24.625 25s-11.025 25-24.625 25S300 119.807 300 106z\"/><path fill=\"#FFF\" d=\"M320.12 105.286V108h4.437c-.18 1.165-1.342 3.415-4.438 3.415-2.671 0-4.85-2.239-4.85-4.998 0-2.76 2.179-5 4.85-5 1.52 0 2.537.657 3.119 1.222l2.123-2.07c-1.363-1.289-3.13-2.069-5.242-2.069-4.325 0-7.824 3.54-7.824 7.917 0 4.376 3.499 7.916 7.824 7.916 4.516 0 7.511-3.212 7.511-7.735 0-.52-.055-.916-.123-1.312h-7.388zM336.885 105.286h-2.235v-2.262h-2.236v2.262h-2.235v2.262h2.235v2.262h2.236v-2.262h2.235\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "300 81 50 50"
    }
  };
  _exports.default = _default;
});