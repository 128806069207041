define("ember-svg-jar/inlined/patreon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"25\" cy=\"25\" r=\"25\" fill=\"#FF5900\"/><path fill=\"#FFF\" d=\"M25.787 13.94c-5.087 0-9.21 4.352-9.21 9.72v12.648h3.684V23.67c0-3.21 2.467-5.813 5.507-5.813 3.043 0 5.507 2.605 5.507 5.813 0 3.21-2.466 5.812-5.508 5.812a5.279 5.279 0 01-3.013-.947v4.177c.579.378 1.905.645 3.458.658 4.888-.237 8.784-4.493 8.784-9.71 0-5.369-4.124-9.722-9.21-9.722zM25.4 33.37c.129.016.258.029.387.029.135 0 .27-.013.405-.029H25.4z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});