define("ember-svg-jar/inlined/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 2v2H4a2 2 0 00-2 2v11a2 2 0 002 2h7.344A5.968 5.968 0 0111 17H4V8h12v3.094A5.998 5.998 0 0117 11c.341 0 .674.039 1 .094V6a2 2 0 00-2-2h-1V2h-2v2H7V2H5zm5 7.25l-.844 1.969L7 11.406l1.625 1.438-.469 2.094L10 13.812l1.844 1.126-.469-2.094L13 11.406l-2.156-.187L10 9.25zM17 12a5 5 0 10.001 10.001A5 5 0 0017 12zm2.125 2.063l1.406 1.406L16 20l-3-3 1.406-1.406L16 17.188l3.125-3.125z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});