define("ember-svg-jar/inlined/lifestyle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M37.969 2.281c-.53.095-.888.506-1.063.844l-7 13.875c-.897-1.205-2.29-2-3.906-2-2.027 0-3.72 1.255-4.5 3-.608 0-1.163.156-1.719.313C19.055 17.56 18.124 17 17 17c-.506 0-.944.176-1.375.344C15.112 17.162 14.582 17 14 17c-2.46 0-4.406 1.839-4.813 4.188C6.84 21.593 5 23.54 5 26H3a1 1 0 00-1 1v3.625C2 40.533 12.641 48 19.531 48H30.47C37.359 48 48 40.533 48 30.625V27a1 1 0 00-1-1h-1.156c-.3-.987-1.017-1.786-1.907-2.313a3.947 3.947 0 00-3.406-3.593 5.179 5.179 0 00-1.218-1.75l6.874-9.688c.349-.5.558-1.395-.562-2.187-1.119-.793-1.89-.137-2.219.375l-6.468 10.25A4.708 4.708 0 0036 17c-1.324 0-2.455.599-3.344 1.438-.203-.077-.419-.147-.625-.22l7.813-13.562c.3-.53.438-1.44-.75-2.125-.446-.257-.807-.307-1.125-.25zM26 17a2.996 2.996 0 012.938 2.375l.218 1.063 1.032-.313A2.94 2.94 0 0131 20c.507 0 .995.135 1.438.375l.718.406.532-.656C34.243 19.435 35.056 19 36 19c1.409 0 2.575.95 2.906 2.25l.188.813.812-.063c.192-.008.217 0 .094 0 1.117 0 2 .883 2 2 0-.092-.02-.048-.031.125l-.032.75.72.25a2.02 2.02 0 011.062.875H27a1 1 0 10-2 0H7c0-1.668 1.332-3 3-3h1v-1c0-1.668 1.332-3 3-3 .43 0 .84.081 1.219.25l.437.219.438-.219c.295-.154.585-.25.906-.25.71 0 1.33.353 1.688.906l.437.688.75-.282A4.477 4.477 0 0121.5 20c.166 0 .324.01.5.031l.875.094.219-.844A2.99 2.99 0 0126 17zm-11 4a1 1 0 100 2 1 1 0 000-2zm3 2a1 1 0 100 2 1 1 0 000-2zm15 0a1 1 0 100 2 1 1 0 000-2z\"/>",
    "attrs": {
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});