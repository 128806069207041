define("kitsu-shared/components/infinite-pagination", ["exports", "ember-invoke-action", "kitsu-shared/templates/components/infinite-pagination", "client/utils/observer-manager"], function (_exports, _emberInvokeAction, _infinitePagination, _observerManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Infinite scrolling wrapper component.
   *
   * Example:
   *
   * {{#infinite-pagination onPagination=(action ...)}}
   *   {{your-work}}
   * {{/infinite-pagination}}
   */
  var _default = Ember.Component.extend({
    layout: _infinitePagination.default,
    tagName: '',
    loaderSize: 'large',
    isTop: false,
    showLoader: true,
    isLoading: Ember.computed.alias('taskInstance.isRunning'),
    init: function init() {
      this._super.apply(this, arguments);

      this.viewportDivId = "infinite-pagination-viewport-".concat(Ember.guidFor(this));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var element = document.querySelector("#".concat(this.viewportDivId));
      var observe = (0, _observerManager.default)(this._getObserverOptions());
      this.unobserve = observe(element, function (entry) {
        if (entry && entry.isIntersecting) {
          var instance = (0, _emberInvokeAction.invokeAction)(_this, 'onPagination');
          Ember.set(_this, 'taskInstance', instance);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.unobserve) {
        this.unobserve();
      }
    },

    /**
     * Returns the config passed to the IntersectionObserver
     *
     * @returns {Object} RootMargin config
     */
    _getObserverOptions: function _getObserverOptions() {
      var defaultOptions = this._getDefaultRootMargin();

      var rootMargin = Object.assign(defaultOptions, Ember.get(this, 'rootMargin') || {});
      rootMargin = "".concat(rootMargin.top, "px ").concat(rootMargin.right, "px ").concat(rootMargin.bottom, "px ").concat(rootMargin.left, "px");
      return {
        root: null,
        rootMargin: rootMargin,
        threshold: 0
      };
    },
    _getDefaultRootMargin: function _getDefaultRootMargin() {
      return {
        top: 0,
        left: 0,
        right: 0,
        bottom: 400
      };
    }
  });

  _exports.default = _default;
});