define("ember-cli-workbox/instance-initializers/sw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getConfig = getConfig;
  _exports.initialize = _initialize;
  _exports.default = void 0;

  function getWithDefault(obj, path, def) {
    var value = Ember.get(obj, path);
    return typeof value !== 'undefined' ? value : def;
  }

  function getConfig(appInstance) {
    var config = appInstance.resolveRegistration('config:environment');
    var isProdBuild = config.environment === 'production';
    return {
      isEnabled: getWithDefault(config, 'ember-cli-workbox.enabled', isProdBuild),
      debugAddon: getWithDefault(config, 'ember-cli-workbox.debug', !isProdBuild),
      swDestFile: getWithDefault(config, 'workbox.swDest', 'sw.js'),
      autoRegister: getWithDefault(config, 'ember-cli-workbox.autoRegister', true)
    };
  }

  function _initialize(appInstance) {
    var swService = appInstance.lookup('service:service-worker');

    var _getConfig = getConfig(appInstance),
        isEnabled = _getConfig.isEnabled,
        debugAddon = _getConfig.debugAddon,
        swDestFile = _getConfig.swDestFile;

    swService.set('debug', debugAddon); // first checks whether the browser supports service workers

    if (swService.get('isSupported')) {
      // Load and register pre-caching Service Worker
      if (isEnabled) {
        swService.register(swDestFile);
      } else {
        swService.unregisterAll();
      }
    } else {
      Ember.debug('Service workers are not supported in this browser.');
    }
  }

  var _default = {
    name: 'ember-cli-workbox',
    initialize: function initialize(appInstance) {
      var _getConfig2 = getConfig(appInstance),
          autoRegister = _getConfig2.autoRegister;

      if (autoRegister) {
        _initialize(appInstance);
      }
    }
  };
  _exports.default = _default;
});