define("ember-svg-jar/inlined/dribbble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#E74D89\" rx=\"25\"/><path fill=\"#B2215A\" d=\"M25 50.004c-13.79 0-25-11.205-25-24.99C0 11.207 11.21.003 25 .003s25 11.204 25 24.99c0 13.785-11.21 25.01-25 25.01zm21.074-21.588c-.722-.235-6.601-1.975-13.3-.92 2.792 7.666 3.925 13.923 4.14 15.214 4.805-3.227 8.223-8.35 9.16-14.294zM33.34 44.685c-.313-1.877-1.563-8.409-4.55-16.191a.362.362 0 01-.138.039c-12.05 4.204-16.367 12.554-16.757 13.336A21.254 21.254 0 0025 46.366a20.84 20.84 0 008.34-1.681zm-24.2-5.378c.489-.82 6.348-10.52 17.364-14.098.273-.098.566-.176.84-.254a55.663 55.663 0 00-1.739-3.618c-10.664 3.187-21.015 3.05-21.953 3.031 0 .215-.02.43-.02.645a21.38 21.38 0 005.509 14.294zM4.103 20.653c.957.02 9.746.058 19.746-2.601-3.535-6.296-7.364-11.576-7.91-12.339-5.997 2.816-10.45 8.33-11.836 14.94zM20 4.266c.586.782 4.473 6.062 7.969 12.495 7.597-2.854 10.8-7.156 11.191-7.704A21.274 21.274 0 0025 3.68c-1.719 0-3.398.215-5 .586zm21.523 7.255c-.449.606-4.023 5.201-11.914 8.428a88.402 88.402 0 011.426 3.09c.156.37.313.742.469 1.094 7.11-.9 14.16.548 14.863.685a21.281 21.281 0 00-4.844-13.297z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "51",
      "viewBox": "0 0 50 51"
    }
  };
  _exports.default = _default;
});