define("ember-svg-jar/inlined/awful", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><circle id=\"z\" cx=\"50\" cy=\"50\" r=\"50\"/><circle id=\"A\" cx=\"9.5\" cy=\"9.5\" r=\"9.5\"/><circle id=\"B\" cx=\"9.5\" cy=\"9.5\" r=\"9.5\"/><mask id=\"C\" width=\"108\" height=\"108\" x=\"-4\" y=\"-4\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\"><path fill=\"#fff\" d=\"M-4-4h108v108H-4z\"/><use xlink:href=\"#z\"/></mask><mask id=\"E\" width=\"27\" height=\"27\" x=\"-4\" y=\"-4\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\"><path fill=\"#fff\" d=\"M-4-4h27v27H-4z\"/><use xlink:href=\"#A\"/></mask><mask id=\"F\" width=\"27\" height=\"27\" x=\"-4\" y=\"-4\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\"><path fill=\"#fff\" d=\"M-4-4h27v27H-4z\"/><use xlink:href=\"#B\"/></mask></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(4 4)\"><mask id=\"D\" fill=\"#fff\"><use xlink:href=\"#z\"/></mask><use fill=\"#C64E41\" xlink:href=\"#z\"/><use stroke=\"#5C1001\" stroke-width=\"8\" mask=\"url(#C)\" xlink:href=\"#z\"/><circle cx=\"50\" cy=\"48\" r=\"46\" fill=\"#DA5E51\" mask=\"url(#D)\"/></g><g transform=\"translate(20 51)\"><use fill=\"#FFF\" xlink:href=\"#A\"/><use stroke=\"#5C1001\" stroke-width=\"8\" mask=\"url(#E)\" xlink:href=\"#A\"/><circle cx=\"9\" cy=\"10\" r=\"3\" fill=\"#5C1001\"/></g><g transform=\"translate(68 51)\"><use fill=\"#FFF\" xlink:href=\"#B\"/><use stroke=\"#5C1001\" stroke-width=\"8\" mask=\"url(#F)\" xlink:href=\"#B\"/><circle cx=\"9\" cy=\"10\" r=\"3\" fill=\"#5C1001\"/></g><path stroke=\"#5C1001\" stroke-linecap=\"round\" stroke-width=\"4\" d=\"M67.607 84.928S54.212 73.638 40 85.236\"/></g>",
    "attrs": {
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "108",
      "height": "108",
      "viewBox": "0 0 108 108"
    }
  };
  _exports.default = _default;
});