define("ember-notify/templates/components/ember-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l0zZQJqy",
    "block": "{\"symbols\":[\"message\",\"message\",\"close\",\"&default\"],\"statements\":[[4,\"each\",[[25,[\"messages\"]]],null,{\"statements\":[[4,\"if\",[[26,4]],null,{\"statements\":[[4,\"ember-notify/message\",null,[[\"message\",\"theme\",\"closeAfter\",\"class\"],[[24,1,[]],[25,[\"theme\"]],[25,[\"closeAfter\"]],\"ember-notify clearfix\"]],{\"statements\":[[0,\"      \"],[15,4,[[24,2,[]],[24,3,[]]]],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"ember-notify/message\",null,[[\"message\",\"theme\",\"closeAfter\",\"class\"],[[24,1,[]],[25,[\"theme\"]],[25,[\"closeAfter\"]],\"ember-notify clearfix\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-notify/templates/components/ember-notify.hbs"
    }
  });

  _exports.default = _default;
});