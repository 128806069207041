define("ember-svg-jar/inlined/mobcrush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"25\" cy=\"25\" r=\"25\" fill=\"#FFEC00\"/><path fill=\"#000\" d=\"M29.195 17.21h3.879v7.76h-3.88v-7.76zm3.879-3.881h7.76v23.28h-7.759l-.001-23.28zm-11.64 11.64h7.76v7.761h-7.76v-7.76zm-3.879-7.758h7.76v7.759h-7.762l.002-7.76zm-7.76-3.882h7.759v23.28H9.796l-.001-23.28z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});