define("ember-svg-jar/inlined/lastfm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#000\" rx=\"25\"/><path fill=\"#D51007\" d=\"M23.112 31.79l-1.173-3.18s-1.906 2.12-4.765 2.12c-2.53 0-4.325-2.193-4.325-5.702 0-4.495 2.272-6.104 4.508-6.104 3.226 0 4.252 2.084 5.132 4.752l1.173 3.655c1.173 3.546 3.372 6.397 9.714 6.397 4.545 0 7.624-1.39 7.624-5.044 0-2.96-1.686-4.496-4.839-5.227l-2.346-.512c-1.612-.365-2.089-1.023-2.089-2.12 0-1.243.99-1.974 2.603-1.974 1.76 0 2.712.658 2.859 2.23l3.665-.439C40.56 17.352 38.288 16 34.55 16c-3.3 0-6.525 1.243-6.525 5.227 0 2.486 1.21 4.057 4.252 4.788l2.493.585c1.87.439 2.492 1.206 2.492 2.266 0 1.353-1.32 1.9-3.812 1.9-3.702 0-5.241-1.936-6.121-4.605l-1.21-3.655C24.578 17.754 22.123 16 17.248 16 11.858 16 9 19.4 9 25.174c0 5.556 2.86 8.554 7.991 8.554 4.142 0 6.121-1.938 6.121-1.938z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});