define("ember-svg-jar/inlined/trakt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#FFF\" rx=\"25\"/><path fill=\"#EC222B\" d=\"M25 50C11.219 50 0 38.781 0 25S11.219 0 25 0s25 11.219 25 25-11.219 25-25 25zm0-47.478C12.603 2.522 2.522 12.603 2.522 25c0 12.397 10.081 22.478 22.478 22.478 12.397 0 22.478-10.081 22.478-22.478C47.478 12.603 37.397 2.522 25 2.522zM10.187 38.606c3.657 4 8.944 6.488 14.813 6.488 3 0 5.834-.657 8.39-1.828L19.438 29.35l-9.25 9.256zm29.766-.14a20.118 20.118 0 005.178-13.47c0-8.093-4.765-15.03-11.603-18.25l-12.684 12.66L39.937 38.47l.016-.003zm-20.578-17.56L8.806 31.438l-1.415-1.416 11.118-11.116L31.491 5.938A20.217 20.217 0 0025 4.875C13.881 4.869 4.869 13.881 4.869 25c0 4.525 1.484 8.703 4.04 12.084l10.532-10.53.75.687 15.093 15.093a7.39 7.39 0 00.875-.553L19.438 25.103 9.325 35.22l-1.416-1.413 11.532-11.531.75.688 17.625 17.575c.28-.207.53-.447.78-.657l-19.033-18.99-.175.03-.013-.015zm6.35 2.14l-1.413-1.405 9.976-9.982 1.415 1.438-9.978 9.969v-.02zm9.462-13.702l-11.5 11.5-1.415-1.413 11.5-11.5 1.416 1.422v-.01z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});