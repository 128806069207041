define("ember-svg-jar/inlined/raptr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"25\" cy=\"25\" r=\"25\" fill=\"#F28F2C\"/><path fill=\"#FFF\" d=\"M28.563 39.271c.511-.807 1.89-2.028 1.554-4.684-.038-.413.336-.453.434-.06.532 1.91-.807 4.036-1.988 4.745zm-2.54-25.255c.197.078.217 1.457-.354 1.24-.374-.138.138-1.3.355-1.24zm-9.586 6.575c.315.767.63 1.85 3.032 2.952 9.054 4.015 18.01.512 16.987-5.432-.689-3.977-6.003-8.623-10.512-9.725-6.692-1.654-11.613 3.819-11.87 8.445-.196 3.484.69 6.24.827 9.763.02.119 0 .295-.098.336-.886.177-2.382.57-2.46.786-.099.413.157 2.087.275 2.618.04.198.157.256.256.06.157-.316 0-1.122.177-1.457.118-.197.453-.236 1.083-.236.315 0 1.102-.04 1.083.295-.02.157-.04.354-.197.512-.866.866-1.417 2.52-1.26 3.563.039.236.197.177.256-.079.315-1.26.965-2.086 1.673-2.185.157 0 .157.217.177.335.138.827.492 1.91.768 2.638.67 1.712 1.614 3.346 2.992 4.606.197.177-.02.217-.157.256-.768.157-3.603 1.279-4.056 3.248-.02.177.414-.276.71-.394.196-.08.727-.039 1.968-.039l8.07.02c.69-.04 1.241-.414 1.674-.69 3.13-2.086 5.453-4.39 7.676-7.756.256-.413 3.19-5.451 1.792-4.035-.02.04-3.622 3.307-6.161 3.603-.965.118-1.26.255-1.791-.473-2.678-3.248-7.383-4.665-7.599-5.02-.276-.512-.374-1.83-.767-2.027-.827-.453-1.772-.788-2.953-1.378-1.398-.689-1.595-1.654-1.595-3.11zm-.177 9.94c.846-.452 2.146-1.476 2.283-2.44 0-.256.394-.375.473-.02.255 1.299-1.359 2.185-2.756 2.46zm10.964-18.444c.079-.69-.374-.61-.689-.197a8.025 8.025 0 00-1.26 2.224c-.354.965-.61 2.087-.63 2.736 0 .394.04.512.158.552.197.078.394-.118.551-.355.965-1.26 1.713-3.208 1.87-4.96zM26.319 20c-3.347 0-4.882-1.673-5.768-2.716-1.93-2.264-1.122-5.807 1.496-7.028 3.13-1.476 7.815-.138 9.429 3.523C33.149 17.6 30 20 26.319 20z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});