define("ember-basic-dropdown/templates/components/basic-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VrHjDvdt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[25,[\"publicAPI\",\"uniqueId\"]],[25,[\"publicAPI\",\"isOpen\"]],[25,[\"publicAPI\",\"disabled\"]],[25,[\"publicAPI\",\"actions\"]],[29,\"component\",[[25,[\"triggerComponent\"]]],[[\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\"],[[29,\"readonly\",[[25,[\"publicAPI\"]]],null],[29,\"readonly\",[[25,[\"hPosition\"]]],null],[29,\"action\",[[24,0,[]],\"handleFocus\"],null],[29,\"readonly\",[[25,[\"renderInPlace\"]]],null],[29,\"readonly\",[[25,[\"vPosition\"]]],null]]]],[29,\"component\",[[25,[\"contentComponent\"]]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[29,\"readonly\",[[25,[\"publicAPI\"]]],null],[29,\"readonly\",[[25,[\"hPosition\"]]],null],[29,\"readonly\",[[25,[\"renderInPlace\"]]],null],[29,\"readonly\",[[25,[\"preventScroll\"]]],null],[29,\"readonly\",[[25,[\"rootEventType\"]]],null],[29,\"readonly\",[[25,[\"vPosition\"]]],null],[29,\"readonly\",[[25,[\"destination\"]]],null],[29,\"readonly\",[[25,[\"top\"]]],null],[29,\"readonly\",[[25,[\"left\"]]],null],[29,\"readonly\",[[25,[\"right\"]]],null],[29,\"readonly\",[[25,[\"width\"]]],null],[29,\"readonly\",[[25,[\"height\"]]],null],[29,\"readonly\",[[25,[\"otherStyles\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown.hbs"
    }
  });

  _exports.default = _default;
});