define("ember-svg-jar/inlined/medium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#FFF\" rx=\"25\"/><path fill=\"#3BFB95\" d=\"M31.124 11.333c-.481-.248-.875.003-.875.557v22.054l8.782 4.526c.966.497 1.75.149 1.75-.779V16.453a.236.236 0 00-.126-.21l-9.53-4.91\"/><path fill=\"#1EE279\" d=\"M19.718 28.518L29.737 11.74c.282-.472.906-.655 1.387-.407l9.542 4.916a.123.123 0 01.047.172L30.25 33.944l-10.53-5.426\"/><path fill=\"#20C66E\" d=\"M10.645 11.633c-.802-.413-1.074-.107-.604.68l9.677 16.205 10.531 5.426-10.508-17.598a.153.153 0 00-.062-.057l-9.034-4.656\"/><path fill=\"#00AB6C\" d=\"M19.718 37.691c0 .924-.656 1.342-1.458.929l-8.198-4.225c-.481-.248-.875-.904-.875-1.459v-20.71c0-.74.525-1.074 1.166-.743l9.308 4.796a.106.106 0 01.057.095V37.69\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});