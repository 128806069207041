define("ember-svg-jar/inlined/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 3c-.6 0-1 .4-1 1v3H4c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h3v4H4c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h3v3c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-3h4v3c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-3h3c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1h-3v-4h3c.6 0 1-.4 1-1V8c0-.6-.4-1-1-1h-3V4c0-.6-.4-1-1-1h-2c-.6 0-1 .4-1 1v3h-4V4c0-.6-.4-1-1-1H8zm3 8h4v4h-4v-4z\"/>",
    "attrs": {
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});