define("ember-apollo-client/-private/mixins/base-query-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    apolloService: Ember.inject.service('apollo'),
    apollo: Ember.computed('apolloService', function () {
      return this.get('apolloService').createQueryManager();
    })
  });

  _exports.default = _default;
});