define('ember-can/computed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ability = ability;
  function ability(abilityName, resourceName) {
    resourceName = resourceName || abilityName;

    return Ember.computed(resourceName, function () {
      var canService = Ember.getOwner(this).lookup('service:can');
      return canService.abilityFor(abilityName, this.get(resourceName));
    });
  }
});