define("kitsu-shared/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['loadingSizeClass', 'white'],
    classNames: ['loading'],
    tagName: 'span',
    size: 'small',
    white: false,
    init: function init() {
      this.loadingSizeClass = "loading--".concat(Ember.get(this, 'size'));

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});