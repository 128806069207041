define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#000\" fill-rule=\"nonzero\" d=\"M13 0c7.179 0 13 5.82 13 13 0 7.179-5.821 13-13 13-7.18 0-13-5.821-13-13C0 5.82 5.82 0 13 0zm-2 7.005v.048c0 1.105.898 2.006 2.005 2.006h.048a2.007 2.007 0 002.006-2.006v-.048A2.007 2.007 0 0013.053 5h-.048A2.007 2.007 0 0011 7.005zm0 4.99v8.01c0 .54.45.995 1.003.995h2.052a1 1 0 001.004-.995v-8.01c0-.54-.45-.995-1.004-.995h-2.052a1 1 0 00-1.003.995z\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});