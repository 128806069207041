define("ember-svg-jar/inlined/more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#CACACA\" fill-rule=\"evenodd\" d=\"M3 0a3 3 0 100 6 3 3 0 000-6zm9 0a3 3 0 100 6 3 3 0 000-6zm9 0a3 3 0 100 6 3 3 0 000-6z\"/>",
    "attrs": {
      "width": "24",
      "height": "6",
      "viewBox": "0 0 24 6"
    }
  };
  _exports.default = _default;
});