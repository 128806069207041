define("ember-svg-jar/inlined/current", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.584 0a.5.5 0 00-.463.307.484.484 0 00.115.537 5.81 5.81 0 010 8.339.485.485 0 00-.137.478.496.496 0 00.358.35.506.506 0 00.486-.134A6.79 6.79 0 0014 5.013 6.79 6.79 0 0011.943.15a.505.505 0 00-.359-.15zM2.387 0a.504.504 0 00-.342.15A6.793 6.793 0 000 5.003c0 1.89.782 3.61 2.045 4.852.125.128.312.18.487.135a.496.496 0 00.358-.351.485.485 0 00-.138-.478 5.808 5.808 0 010-8.317A.484.484 0 002.865.3a.5.5 0 00-.478-.298zm7.078 2.086A.5.5 0 009 2.393a.484.484 0 00.116.536 2.9 2.9 0 010 4.168.485.485 0 00-.13.475c.046.17.18.302.353.348a.507.507 0 00.484-.127A3.883 3.883 0 0011 5.013c0-1.083-.45-2.07-1.176-2.78a.505.505 0 00-.36-.147zm-4.953.006a.504.504 0 00-.344.15A3.885 3.885 0 003 5.012c0 1.08.448 2.063 1.168 2.772a.507.507 0 00.708.001.485.485 0 00.001-.695 2.9 2.9 0 010-4.156.484.484 0 00.113-.545.5.5 0 00-.478-.298zM7 4.032a.99.99 0 00-1 .981.99.99 0 001 .982c.552 0 1-.44 1-.982a.99.99 0 00-1-.981z\"/>",
    "attrs": {
      "viewBox": "0 -2 16 16"
    }
  };
  _exports.default = _default;
});