define("ember-svg-jar/inlined/vrv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#1b1a26\" d=\"M26 21l-10 6-10-6v-9h20v9z\" class=\"hide-inactive\"/><path fill=\"#fd0\" d=\"M2.63 8.19v15.62L16 31.62l13.37-7.81V8.19L16 .38 2.63 8.19zm22.11 12.72L16 26l-8.73-5.1v-6.46l8.73 5.1 8.73-5.1.01 6.47z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});