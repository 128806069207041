define("ember-intl/-private/utils/flatten", ["exports", "ember-intl/-private/utils/empty-object"], function (_exports, _emptyObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flatten;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var hasOwnProperty = Object.prototype.hasOwnProperty;
  /**
   * @private
   * @hide
   */

  function flatten(src) {
    var result = new _emptyObject.default();

    for (var key in src) {
      if (!hasOwnProperty.call(src, key)) {
        continue;
      }

      var value = src[key];

      if (_typeof(value) === 'object' && value) {
        var hash = flatten(value);

        for (var suffix in hash) {
          result["".concat(key, ".").concat(suffix)] = hash[suffix];
        }
      } else {
        result[key] = value;
      }
    }

    return result;
  }
});