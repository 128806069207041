define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M2aWzc18",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\"],[12,\"id\",[23,\"for\"]],[12,\"accept\",[23,\"accept\"]],[12,\"capture\",[23,\"capture\"]],[12,\"multiple\",[23,\"multiple\"]],[12,\"disabled\",[23,\"disabled\"]],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"change\"],[[\"value\"],[\"target.files\"]]]],[11,\"hidden\",\"\"],[11,\"type\",\"file\"],[9],[10],[15,1,[[25,[\"queue\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});