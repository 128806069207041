define("ember-svg-jar/inlined/imdb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#E6B900\" rx=\"25\"/><g fill=\"#000\"><path d=\"M40.91 28.914c0 .76-.038 1.24-.114 1.44-.077.2-.41.3-.661.3-.246 0-.41-.096-.493-.29-.083-.193-.124-.634-.124-1.324v-4.152c0-.715.036-1.161.11-1.34.072-.176.231-.265.477-.265.252 0 .59.101.676.304.086.203.13.637.13 1.3v4.027zm-5.37-11.897v15.708h3.717l.258-1c.336.402.706.703 1.112.905.405.2 1.01.3 1.479.3.652 0 1.216-.169 1.69-.507.475-.339.777-.739.906-1.2.128-.461.193-1.163.193-2.105v-4.407c0-.948-.022-1.567-.065-1.857-.043-.29-.17-.587-.38-.89-.211-.303-.517-.539-.92-.707-.401-.168-.875-.252-1.422-.252-.475 0-1.083.094-1.488.279a3.27 3.27 0 00-1.102.843v-5.11H35.54zM29.8 19.844c.163.093.268.24.313.44.046.2.069.656.069 1.366v6.09c0 1.045-.069 1.686-.206 1.921-.137.236-.503.354-1.096.354V19.704c.45 0 .757.046.92.14zm-.043 12.881c.99 0 1.73-.053 2.221-.16.492-.106.904-.294 1.239-.562.334-.267.568-.639.703-1.114.134-.474.214-1.416.214-2.824V22.55c0-1.486-.06-2.482-.15-2.99a2.807 2.807 0 00-.689-1.38c-.367-.413-.903-.71-1.607-.89-.704-.181-1.854-.272-3.853-.272h-3.08v15.708h5.002zM17.949 17.017l-.957 7.338-.591-3.99c-.173-1.281-.338-2.397-.495-3.348h-5.353v15.708h3.616l.013-10.372 1.522 10.372h2.576l1.444-10.602.013 10.602h3.606V17.017h-5.394zM5.014 17.017h4.129v15.708H5.014z\"/></g></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});