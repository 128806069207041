define("ember-svg-jar/inlined/upcoming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 11.029h1v.471c0 .281.219.5.5.5h11.408c-.207.581-.757 1-1.408 1h-10C.677 13 0 12.323 0 11.5v-.471zM2 0h1v1h7V0h1v1h.5c.823 0 1.5.677 1.5 1.5V7h-1V5H1.003v1.01H0V2.5C0 1.677.677 1 1.5 1H2V0zM1 7h1v1h1v1H2v1H1V9H0V8h1V7zm8.158 1.143c.447-.118 1.075.008 1.342.25.267-.242.966-.362 1.342-.25C12.48 8.314 13 8.81 13 9.5V11h-1V9.5c0-.281-.219-.5-.5-.5s-.5.219-.5.5V11h-1V9.5c0-.281-.219-.5-.5-.5s-.5.219-.5.5V11H8V9.5c0-.69.52-1.186 1.158-1.357zm-2.252-.936c.118.447-.008 1.075-.25 1.342.242.267.363.966.25 1.342-.171.639-.667 1.158-1.357 1.158h-1.5v-1h1.5c.282 0 .5-.218.5-.5 0-.281-.218-.5-.5-.5h-1.5v-1h1.5c.282 0 .5-.218.5-.5 0-.281-.218-.5-.5-.5h-1.5v-1h1.5c.69 0 1.186.52 1.357 1.158zM1.5 2c-.281 0-.5.219-.5.5V4h11V2.5c0-.281-.219-.5-.5-.5H11v1h-1V2H3v1H2V2h-.5z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});