define('ember-can/utils/normalize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (string) {
    var parts = string.split(' ');
    var abilityName = parts.pop();
    var last = parts[parts.length - 1];

    if (stopWords.includes(last)) {
      parts.pop();
    }

    var ability = Ember.String.classify(parts.join(' '));
    var propertyName = 'can' + ability;

    return { propertyName: propertyName, abilityName: abilityName };
  };

  var stopWords = ['of', 'in', 'for', 'to', 'from', 'on'];
});