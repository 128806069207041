define("ember-svg-jar/inlined/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 5v23h9V5H5zm2 2h5v5H7V7zm1 1v3h3V8H8zm8 0v2h5V8h-5zm7 0v2h5V8h-5zM7 14h5v5H7v-5zm2.5 1a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.5 0v2h5v-2h-5zm7 0v2h5v-2h-5zM7 21h5v5H7v-5zm2.5 1L8 25h3l-1.5-3zm6.5 0v2h5v-2h-5zm7 0v2h5v-2h-5z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});