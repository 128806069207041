define("ember-intl/-private/store/container", ["exports", "ember-intl/-private/store/translation"], function (_exports, _translation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @private
   * @hide
   */
  var _default = Ember.Object.extend({
    locales: Ember.computed('_translations', function () {
      return Array.from(this._translations.keys());
    }).readOnly(),
    init: function init() {
      this._super();

      this._translations = new Map();
    },
    createTranslationModel: function createTranslationModel(localeName) {
      var translationModel = _translation.default.create({
        localeName: localeName
      });

      this._translations.set(localeName, translationModel);

      this.notifyPropertyChange('locales');
      return translationModel;
    },
    findTranslationModel: function findTranslationModel(localeName) {
      return this._translations.get(localeName);
    },
    push: function push(localeName, payload) {
      var translationModel = this.findTranslationModel(localeName);

      if (!translationModel) {
        translationModel = this.createTranslationModel(localeName);
      }

      translationModel.addTranslations(payload);
    },
    has: function has(localeName, key) {
      var translationModel = this.findTranslationModel(localeName);

      if (translationModel) {
        return translationModel.has(key);
      }
    },
    _lookup: function _lookup(localeName, key) {
      var translationModel = this.findTranslationModel(localeName);

      if (translationModel && translationModel.has(key)) {
        return translationModel.find(key);
      }
    },
    lookupAst: function lookupAst(localeName, key) {
      var translationResult = this._lookup(localeName, key);

      if (translationResult) {
        return translationResult.ast;
      }
    },
    lookup: function lookup(localeName, key) {
      var translationResult = this._lookup(localeName, key);

      if (translationResult) {
        return translationResult.original;
      }
    }
  });

  _exports.default = _default;
});