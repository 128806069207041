define("ember-svg-jar/inlined/deviantart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#475C4E\" rx=\"25\"/><path fill=\"#05CC47\" d=\"M35.426 15V9H29.425l-.598.604L25.995 15l-.89.6H15v8.24h5.555l.495.599L15 36v6h6l.599-.604L24.43 36l.891-.6h10.105v-8.24H29.87l-.494-.603z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});