define("ember-notify/components/ember-notify/message", ["exports", "ember-notify/templates/components/ember-notify/message"], function (_exports, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_MESSAGE = {};

  var _default = Ember.Component.extend({
    layout: _message.default,
    message: DEFAULT_MESSAGE,
    closeAfter: null,
    run: null,
    classNameBindings: ['message.visible:ember-notify-show:ember-notify-hide', 'radius::', 'themeClassNames', 'message.classNames'],
    attributeBindings: ['data-alert'],
    'data-alert': '',
    init: function init() {
      this._super.apply(this, arguments); // Indicate that the message is now being displayed


      if (this.message.visible === undefined) {
        // Should really be in didInsertElement but Glimmer doesn't allow this
        this.set('message.visible', true);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var _this$message = this.message,
          _this$message$closeAf = _this$message.closeAfter,
          closeAfter = _this$message$closeAf === void 0 ? this.closeAfter : _this$message$closeAf,
          element = _this$message.element;

      if (element) {
        if (Ember.isArray(element)) {
          // eslint-disable-line ember/no-jquery
          this.$('.message').append(element);
        } else {
          this.element.querySelector('.message').appendChild(element);
        }
      }

      if (closeAfter) {
        Ember.run.later(function () {
          return _this.selfClose();
        }, closeAfter);
      }
    },
    themeClassNames: Ember.computed('theme', 'message.type', function () {
      return this.theme ? this.theme.classNamesFor(this.message) : '';
    }),
    actions: {
      close: function close() {
        if (this.message.closed) {
          return;
        }

        this.set('message.closed', true);
        this.set('message.visible', false);
        var removeAfter = this.message.removeAfter || this.constructor.removeAfter;

        if (removeAfter) {
          Ember.run.later(this, remove, removeAfter);
        } else {
          remove();
        }

        function remove() {
          if (this.isDestroyed || !this.parentView || !this.parentView.messages) {
            return;
          }

          this.parentView.messages.removeObject(this.message);
          this.set('message.visible', null);
        }
      }
    },
    isHovering: function isHovering() {
      return this.element.matches ? this.element.matches(':hover') : this.element.msMatchesSelector(':hover');
    },
    selfClose: function selfClose() {
      var _this2 = this;

      if (this.isDestroyed) {
        return;
      }

      if (this.isHovering()) {
        return Ember.run.later(function () {
          return _this2.selfClose();
        }, 100);
      } // When :hover no longer applies, close as normal


      this.send('close');
    }
  }).reopenClass({
    removeAfter: 250 // Allow time for the close animation to finish

  });

  _exports.default = _default;
});