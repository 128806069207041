define("ember-svg-jar/inlined/good", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(2 2)\"><circle cx=\"50\" cy=\"50\" r=\"50\" fill=\"#78CB64\" stroke=\"#176605\" stroke-width=\"4\"/><circle cx=\"50\" cy=\"48\" r=\"46\" fill=\"#84E06F\"/></g><path d=\"M39.042 78.068s13.197 11.523 27.61.173\" stroke=\"#9F0800\" stroke-linecap=\"round\" stroke-width=\"4\"/><g transform=\"translate(24 56)\"><circle cx=\"4\" cy=\"4\" r=\"4\" fill=\"#176605\"/><circle cx=\"54\" cy=\"4\" r=\"4\" fill=\"#176605\"/><path stroke=\"#176605\" stroke-linecap=\"round\" stroke-width=\"4\" d=\"M15.042 22.068s13.197 11.523 27.61.173\"/></g></g>",
    "attrs": {
      "width": "104",
      "height": "104",
      "viewBox": "0 0 104 104"
    }
  };
  _exports.default = _default;
});