define('ember-cli-password-strength/services/password-strength', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    loadingPromise: null,
    load: function load() {
      var loadingPromise = this.get('loadingPromise');
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      if (!loadingPromise) {
        loadingPromise = new Ember.RSVP.Promise(function (resolve, reject) {
          //ensure the script was not already loaded elsewhere
          if ('zxcvbn' in window) {
            resolve(true);
          } else {
            var script = document.createElement('script');
            var url = config.rootURL + 'zxcvbn.js';
            script.src = url;
            script.type = 'text/javascript';
            script.async = true;
            var first = document.getElementsByTagName('script')[0];
            first.parentNode.insertBefore(script, first);
            script.onload = function () {
              resolve(true);
            };
            script.onerror = function () {
              var error = new Error('Could not load Zxcvbn from ' + url);
              reject(error);
            };
          }
        });
        this.set('loadingPromise', loadingPromise);
      }

      return loadingPromise;
    },


    /**
     * Load zxcvbn and then calculate strength
     * @param {String} password
     * @param {Array} user_inputs
     *
     * @returns {Promise}
     */
    strength: function strength(password) {
      var _this = this;

      var user_inputs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      return new Ember.RSVP.Promise(function (resolve) {
        _this.load().then(function () {
          resolve(window['zxcvbn'](password, user_inputs));
        });
      });
    },


    /**
     * Use a previously loaded zxcvbn to calculate strength
     * @param {String} password
     * @param {Array} user_inputs
     *
     * @returns {Object}
     */
    strengthSync: function strengthSync(password) {
      var user_inputs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      if (!('zxcvbn' in window)) {
        throw new Error('`strengthSync` called before `load` was finished.');
      }
      return window['zxcvbn'](password, user_inputs);
    },


    /**
     * Load zxcvbn and then calculate strength
     * Return a ObjectProxy Promise which is suitable for use in a template
     * @param {String} password
     * @param {Array} user_inputs
     *
     * @returns {ObjectPromiseProxy}
     */
    strengthProxy: function strengthProxy(password) {
      var user_inputs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
      var promise = this.strength(password, user_inputs);
      return ObjectPromiseProxy.create({ promise: promise });
    }
  });
});