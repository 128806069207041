define("ember-svg-jar/inlined/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#3B5998\" d=\"M0 25C0 11.193 11.193 0 25 0s25 11.193 25 25-11.193 25-25 25S0 38.807 0 25z\"/><path fill=\"#FFF\" d=\"M26.772 38.866V25.265h3.755l.498-4.687h-4.253l.007-2.346c0-1.223.116-1.878 1.872-1.878h2.347v-4.687h-3.755c-4.51 0-6.098 2.273-6.098 6.097v2.814h-2.812v4.687h2.812v13.601h5.627z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});