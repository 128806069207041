define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.469 2.563l-.719.75c-1.045 1.149-1.542 2.605-1.656 4.062l-4.063 4.063c-2.472-.44-5.128.253-7.031 2.156l-.719.719L7 15l4.313 4.313L3 27.593V29h1.406l8.282-8.313 4.218 4.22.688.718.718-.719c2.096-2.095 2.691-4.974 2.063-7.562l3.563-3.563c1.737.207 3.513-.294 4.78-1.562l.688-.719-.687-.719-7.5-7.5-.75-.719zm.218 3.062l5.688 5.688c-.825.466-1.716.758-2.625.53l-.563-.155-.406.406-4.187 4.187-.438.438.188.593c.587 1.763.315 3.75-.844 5.375L9.312 14.5c1.455-1 3.206-1.459 4.844-1.031l.531.156.407-.406 4.625-4.625.281-.281v-.407c0-.803.293-1.563.688-2.281z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});