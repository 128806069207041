define("ember-intl/helpers/t", ["exports", "ember-intl/-private/helpers/-format-base"], function (_exports, _formatBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  var _default = _formatBase.default.extend({
    format: function format(key, options) {
      return this.intl.t(key, options);
    }
  });

  _exports.default = _default;
});