define("ember-svg-jar/inlined/letterboxd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"50\" height=\"50\" fill=\"#25272B\" rx=\"25\"/><path fill=\"#6DB746\" d=\"M29.854 22.08c-1.02-1.718-2.877-2.871-5.005-2.871-2.129 0-3.985 1.153-5.005 2.87a5.901 5.901 0 010 6.051c1.02 1.717 2.876 2.87 5.005 2.87 2.128 0 3.984-1.153 5.005-2.87a5.9 5.9 0 010-6.05\"/><path fill=\"#EC914A\" d=\"M19.844 22.08c-1.02-1.718-2.877-2.871-5.005-2.871-3.226 0-5.839 2.64-5.839 5.896S11.613 31 14.839 31c2.128 0 3.984-1.153 5.005-2.87a5.9 5.9 0 010-6.05\"/><path fill=\"#F8E36E\" d=\"M19.844 22.08a5.901 5.901 0 000 6.05 5.9 5.9 0 000-6.05\"/><path fill=\"#9FC4DE\" d=\"M34.859 19.209c-2.128 0-3.985 1.153-5.005 2.87a5.901 5.901 0 010 6.051c1.02 1.717 2.877 2.87 5.005 2.87 3.225 0 5.839-2.64 5.839-5.895 0-3.256-2.614-5.896-5.84-5.896\"/><path fill=\"#CAE7E4\" d=\"M29.854 22.08a5.901 5.901 0 000 6.05 5.9 5.9 0 000-6.05\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});