define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4 5.75v4.5q0 .109-.07.18t-.18.07h-.5q-.109 0-.18-.07T3 10.25v-4.5q0-.109.07-.18t.18-.07h.5q.109 0 .18.07t.07.18zm2 0v4.5q0 .109-.07.18t-.18.07h-.5q-.109 0-.18-.07T5 10.25v-4.5q0-.109.07-.18t.18-.07h.5q.109 0 .18.07t.07.18zm2 0v4.5q0 .109-.07.18t-.18.07h-.5q-.109 0-.18-.07T7 10.25v-4.5q0-.109.07-.18t.18-.07h.5q.109 0 .18.07t.07.18zm1 5.656V4H2v7.406q0 .172.055.316t.113.211.082.066h6.5q.023 0 .082-.066t.113-.211.055-.316zM3.75 3h3.5l-.375-.914Q6.82 2.016 6.742 2H4.265q-.078.016-.133.086zm7.25.25v.5q0 .109-.07.18t-.18.07H10v7.406q0 .648-.367 1.121T8.75 13h-6.5q-.516 0-.883-.457T1 11.438V4H.25q-.109 0-.18-.07T0 3.75v-.5q0-.109.07-.18T.25 3h2.414l.547-1.305q.117-.289.422-.492T4.25 1h2.5q.312 0 .617.203t.422.492L8.336 3h2.414q.109 0 .18.07t.07.18z\"/>",
    "attrs": {
      "width": "11",
      "height": "14",
      "viewBox": "0 0 11 14"
    }
  };
  _exports.default = _default;
});