define("ember-svg-jar/inlined/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.288 20.131L15.234 2.719c-1.229-2.129-3.239-2.129-4.469 0L.712 20.131C-.518 22.262.488 24 2.945 24h20.109c2.458 0 3.463-1.738 2.234-3.869zM11.619 7.659c.361-.39.82-.586 1.381-.586s1.02.193 1.381.578c.359.386.539.869.539 1.449 0 .499-.75 4.169-1 6.839h-1.807c-.22-2.67-1.033-6.34-1.033-6.839 0-.572.18-1.052.539-1.441zm2.736 12.695c-.38.37-.832.555-1.355.555s-.976-.185-1.355-.555a1.802 1.802 0 01-.567-1.342c0-.522.188-.975.567-1.354.38-.379.832-.568 1.355-.568s.976.189 1.355.568c.379.379.567.831.567 1.354.001.526-.188.973-.567 1.342z\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});