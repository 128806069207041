define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.326 10.137a1.001 1.001 0 00-.807-.68l-7.34-1.066-3.283-6.651c-.337-.683-1.456-.683-1.793 0L8.82 8.391 1.48 9.457a1 1 0 00-.554 1.705l5.312 5.178-1.254 7.31a1.001 1.001 0 001.451 1.054L13 21.252l6.564 3.451a1 1 0 001.451-1.054l-1.254-7.31 5.312-5.178a.998.998 0 00.253-1.024z\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
  _exports.default = _default;
});