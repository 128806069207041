define("ember-svg-jar/inlined/trailer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M26.443 4.968s.262 2.205.262 4.41v2.068c0 2.205-.262 4.41-.262 4.41s-.257 1.878-1.043 2.705c-.998 1.086-2.116 1.09-2.628 1.155-3.67.275-9.182.284-9.182.284s-6.82-.065-8.918-.274c-.583-.113-1.894-.08-2.892-1.165-.787-.827-1.042-2.705-1.042-2.705s-.263-2.205-.263-4.41V9.378c0-2.205.263-4.41.263-4.41S.993 3.09 1.78 2.263c.998-1.086 2.115-1.09 2.628-1.154 3.67-.276 9.176-.276 9.176-.276h.012s5.506 0 9.176.276c.512.063 1.63.068 2.628 1.154.786.827 1.043 2.705 1.043 2.705zM10.882 13.95l7.086-3.815-7.087-3.841.001 7.656z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "27",
      "height": "20",
      "viewBox": "0 0 27 20"
    }
  };
  _exports.default = _default;
});