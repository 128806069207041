define("ember-svg-jar/inlined/cal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.875 4.958H2.792v1.084h1.083V4.958zm2.167 0H4.958v1.084h1.084V4.958zm2.166 0H7.125v1.084h1.083V4.958zm1.084-3.791H8.75V.083H7.667v1.084H3.333V.083H2.25v1.084h-.542c-.6 0-1.078.487-1.078 1.083L.625 9.833c0 .596.482 1.084 1.083 1.084h7.584c.595 0 1.083-.488 1.083-1.084V2.25c0-.596-.488-1.083-1.083-1.083zm0 8.666H1.708V3.875h7.584v5.958z\" fill=\"#686868\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "11",
      "height": "11",
      "viewBox": "0 0 11 11"
    }
  };
  _exports.default = _default;
});