define("ember-svg-jar/inlined/tumblr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"25\" cy=\"25\" r=\"25\" fill=\"#314358\"/><path fill=\"#FFF\" d=\"M30.478 32.296c-.425.21-1.24.392-1.846.408-1.833.053-2.19-1.332-2.204-2.334V23h4.597v-3.587h-4.583V13.38h-3.35c-.056 0-.153.05-.167.177-.197 1.848-1.03 5.09-4.504 6.384V23h2.316v7.737c0 2.651 1.89 6.416 6.877 6.329 1.685-.032 3.553-.76 3.965-1.39l-1.101-3.375\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});