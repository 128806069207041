define('ember-can/services/can', ['exports', 'ember-can/utils/normalize'], function (exports, _normalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parse: function parse(abilityString) {
      return (0, _normalize.default)(abilityString);
    },
    build: function build() {
      Ember.deprecate('Usage of "build" is deprecated, use "abilityFor" instead.', false, {
        id: 'ember-can.deprecate-service-build',
        until: '2.0.0',
        url: 'https://github.com/minutebase/ember-can#looking-up-abilities'
      });

      return this.abilityFor.apply(this, arguments);
    },
    abilityFor: function abilityFor(abilityName, model) {
      var properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var ability = Ember.getOwner(this).lookup('ability:' + abilityName);
      (false && !(ability) && Ember.assert('No ability type found for ' + abilityName, ability));


      ability.setProperties(Ember.assign({}, { model: model }, properties));
      return ability;
    },
    can: function can(abilityString, model, properties) {
      var _parse = this.parse(abilityString),
          abilityName = _parse.abilityName,
          propertyName = _parse.propertyName;

      var ability = this.abilityFor(abilityName, model, properties);

      return ability.get(propertyName);
    },
    cannot: function cannot(abilityString, model, properties) {
      return !this.can(abilityString, model, properties);
    }
  });
});