define("ember-svg-jar/inlined/sm-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.64 9.001l-.14-.116c-.264-.22-.621-.46-1.035-.737C2.851 7.068.64 5.588.64 3.066A2.83 2.83 0 013.466.24c.847 0 1.64.377 2.174 1.022A2.819 2.819 0 017.814.24a2.83 2.83 0 012.826 2.826c0 2.522-2.21 4.002-3.825 5.082-.414.277-.771.517-1.036.737l-.139.116z\" fill=\"#686868\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "11",
      "height": "9",
      "viewBox": "0 0 11 9"
    }
  };
  _exports.default = _default;
});