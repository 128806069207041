define("ember-svg-jar/inlined/tba", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.5 0A6.508 6.508 0 000 6.5C0 10.084 2.916 13 6.5 13S13 10.084 13 6.5 10.084 0 6.5 0zm0 1C9.543 1 12 3.457 12 6.5S9.543 12 6.5 12A5.492 5.492 0 011 6.5C1 3.457 3.457 1 6.5 1zm.059 2.002A2.337 2.337 0 004.36 5.318h1c0-.812.703-1.446 1.559-1.298.616.105 1.1.708 1.08 1.34-.017.555-.339.907-.816 1.117v.002c-.297.13-.579.256-.817.505C6.13 7.234 6 7.604 6 8h1c0-.225.03-.261.092-.326s.224-.162.494-.281C8.332 7.065 8.97 6.353 9 5.39c.035-1.139-.78-2.164-1.91-2.358a2.351 2.351 0 00-.531-.031zM6 9v1h1V9H6z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});