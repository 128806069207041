define("ember-apollo-client/services/apollo", ["exports", "apollo-client", "apollo-link-http", "apollo-cache-inmemory", "ember-apollo-client", "ember-apollo-client/apollo/query-manager", "ember-apollo-client/utils/copy-with-extras", "fetch"], function (_exports, _apolloClient, _apolloLinkHttp, _apolloCacheInmemory, _emberApolloClient, _queryManager, _copyWithExtras, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmberApolloSubscription = Ember.Object.extend(Ember.Evented, {
    lastEvent: null,
    apolloUnsubscribe: function apolloUnsubscribe() {
      this.get('_apolloClientSubscription').unsubscribe();
    },
    _apolloClientSubscription: null,
    _onNewData: function _onNewData(newData) {
      this.set('lastEvent', newData);
      this.trigger('event', newData);
    }
  });

  function extractNewData(resultKey, _ref) {
    var data = _ref.data,
        loading = _ref.loading;

    if (loading && Ember.isNone(data)) {
      // This happens when the cache has no data and the data is still loading
      // from the server. We don't want to resolve the promise with empty data
      // so we instead just bail out.
      //
      // See https://github.com/bgentry/ember-apollo-client/issues/45
      return null;
    }

    var keyedData = Ember.isNone(resultKey) ? data : data && Ember.get(data, resultKey);
    return (0, _copyWithExtras.default)(keyedData || {}, [], []);
  }

  function newDataFunc(observable, resultKey, resolve) {
    var mergedProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var obj;
    mergedProps[_emberApolloClient.apolloObservableKey] = observable;
    return function (newData) {
      var dataToSend = extractNewData(resultKey, newData);

      if (dataToSend === null) {
        // see comment in extractNewData
        return;
      }

      if (Ember.isNone(obj)) {
        if (Ember.isArray(dataToSend)) {
          obj = Ember.A(dataToSend);
          obj.setProperties(mergedProps);
        } else {
          obj = Ember.Object.create(Ember.assign(dataToSend, mergedProps));
        }

        return resolve(obj);
      }

      Ember.run(function () {
        Ember.isArray(obj) ? obj.setObjects(dataToSend) : Ember.setProperties(obj, dataToSend);
      });
    };
  } // used in environments without injected `config:environment` (i.e. unit tests):


  var defaultOptions = {
    apiURL: 'http://testserver.example/v1/graph'
  };

  var _default = Ember.Service.extend({
    client: null,
    apiURL: Ember.computed.alias('options.apiURL'),
    requestCredentials: Ember.computed.alias('options.requestCredentials'),
    // options are configured in your environment.js.
    options: Ember.computed(function () {
      // config:environment not injected into tests, so try to handle that gracefully.
      var config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config && config.apollo) {
        return config.apollo;
      } else if (Ember.testing) {
        return defaultOptions;
      }

      throw new Error('no Apollo service options defined');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var owner = Ember.getOwner(this);

      if (owner) {
        owner.registerOptionsForType('apollo', {
          instantiate: false
        });
      }

      var client = new _apolloClient.ApolloClient(this.get('clientOptions'));
      this.set('client', client);

      if (Ember.testing) {
        this._registerWaiter();
      }
    },

    /**
     * This is the options hash that will be passed to the ApolloClient constructor.
     * You can override it if you wish to customize the ApolloClient.
     *
     * @method clientOptions
     * @return {!Object}
     * @public
     */
    clientOptions: Ember.computed(function () {
      return {
        link: this.get('link'),
        cache: this.get('cache')
      };
    }),
    cache: Ember.computed(function () {
      return new _apolloCacheInmemory.InMemoryCache();
    }),
    link: Ember.computed(function () {
      var uri = this.get('apiURL');
      var requestCredentials = this.get('requestCredentials');
      var linkOptions = {
        uri: uri,
        fetch: _fetch.default
      };

      if (Ember.isPresent(requestCredentials)) {
        linkOptions.credentials = requestCredentials;
      }

      return (0, _apolloLinkHttp.createHttpLink)(linkOptions);
    }),

    /**
     * Executes a mutation on the Apollo client. The resolved object will
     * never be updated and does not have to be unsubscribed.
     *
     * @method mutate
     * @param {!Object} opts The query options used in the Apollo Client mutate.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @public
     */
    mutate: function mutate(opts, resultKey) {
      var _this = this;

      return this._waitFor(new Ember.RSVP.Promise(function (resolve, reject) {
        _this.client.mutate(opts).then(function (result) {
          var dataToSend = Ember.isNone(resultKey) ? result.data : Ember.get(result.data, resultKey);
          dataToSend = (0, _copyWithExtras.default)(dataToSend, [], []);
          return resolve(dataToSend);
        }).catch(function (error) {
          var errors;

          if (Ember.isPresent(error.networkError)) {
            error.networkError.code = 'network_error';
            errors = [error.networkError];
          } else if (Ember.isPresent(error.graphQLErrors)) {
            errors = error.graphQLErrors;
          }

          if (errors) {
            return reject({
              errors: errors
            });
          }

          throw error;
        });
      }));
    },

    /**
     * Executes a `watchQuery` on the Apollo client. If updated data for this
     * query is loaded into the store by another query, the resolved object will
     * be updated with the new data.
     *
     * When using this method, it is important to call `apolloUnsubscribe()` on
     * the resolved data when the route or component is torn down. That tells
     * Apollo to stop trying to send updated data to a non-existent listener.
     *
     * @method watchQuery
     * @param {!Object} opts The query options used in the Apollo Client watchQuery.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @public
     */
    watchQuery: function watchQuery(opts, resultKey) {
      var observable = this.client.watchQuery(opts);
      var subscription;
      var mergedProps = {
        _apolloUnsubscribe: function _apolloUnsubscribe() {
          subscription.unsubscribe();
        }
      };
      mergedProps[_emberApolloClient.apolloObservableKey] = observable;
      return this._waitFor(new Ember.RSVP.Promise(function (resolve, reject) {
        // TODO: add an error function here for handling errors
        subscription = observable.subscribe({
          next: newDataFunc(observable, resultKey, resolve, mergedProps),
          error: function error(e) {
            reject(e);
          }
        });
      }));
    },

    /**
     * Executes a `subscribe` on the Apollo client. If this subscription receives
     * data, the resolved object will be updated with the new data.
     *
     * When using this method, it is important to call `apolloUnsubscribe()` on
     * the resolved data when the route or component is torn down. That tells
     * Apollo to stop trying to send updated data to a non-existent listener.
     *
     * @method subscribe
     * @param {!Object} opts The query options used in the Apollo Client subscribe.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @public
     */
    subscribe: function subscribe(opts) {
      var resultKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var observable = this.get('client').subscribe(opts);
      var obj = EmberApolloSubscription.create();
      return this._waitFor(new Ember.RSVP.Promise(function (resolve, reject) {
        var subscription = observable.subscribe({
          next: function next(newData) {
            var dataToSend = extractNewData(resultKey, newData);

            if (dataToSend === null) {
              // see comment in extractNewData
              return;
            }

            Ember.run(function () {
              return obj._onNewData(dataToSend);
            });
          },
          error: function error(e) {
            reject(e);
          }
        });
        obj.set('_apolloClientSubscription', subscription);
        resolve(obj);
      }));
    },

    /**
     * Executes a single `query` on the Apollo client. The resolved object will
     * never be updated and does not have to be unsubscribed.
     *
     * @method query
     * @param {!Object} opts The query options used in the Apollo Client query.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @public
     */
    query: function query(opts, resultKey) {
      var _this2 = this;

      return this._waitFor(new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.client.query(opts).then(function (result) {
          var response = result.data;

          if (!Ember.isNone(resultKey)) {
            response = Ember.get(response, resultKey);
          }

          return resolve((0, _copyWithExtras.default)(response, [], []));
        }).catch(function (error) {
          return reject(error);
        });
      }));
    },

    /**
     * Executes a `watchQuery` on the Apollo client and tracks the resulting
     * subscription on the provided query manager.
     *
     * @method managedWatchQuery
     * @param {!Object} manager A QueryManager that should track this active watchQuery.
     * @param {!Object} opts The query options used in the Apollo Client watchQuery.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @private
     */
    managedWatchQuery: function managedWatchQuery(manager, opts, resultKey) {
      var observable = this.client.watchQuery(opts);
      return this._waitFor(new Ember.RSVP.Promise(function (resolve, reject) {
        var subscription = observable.subscribe({
          next: newDataFunc(observable, resultKey, resolve),
          error: function error(e) {
            reject(e);
          }
        });
        manager.trackSubscription(subscription);
      }));
    },

    /**
     * Executes a `subscribe` on the Apollo client and tracks the resulting
     * subscription on the provided query manager.
     *
     * @method managedSubscribe
     * @param {!Object} manager A QueryManager that should track this active subscribe.
     * @param {!Object} opts The query options used in the Apollo Client subscribe.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @private
     */
    managedSubscribe: function managedSubscribe(manager, opts) {
      var resultKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      return this.subscribe(opts, resultKey).then(function (obj) {
        manager.trackSubscription(obj._apolloClientSubscription);
        return obj;
      });
    },
    createQueryManager: function createQueryManager() {
      return _queryManager.default.create({
        apollo: this
      });
    },

    /**
     * Wraps a promise in test waiters.
     *
     * @param {!Promise} promise
     * @return {!Promise}
     * @private
     */
    _waitFor: function _waitFor(promise) {
      var _this3 = this;

      this._incrementOngoing();

      return promise.finally(function () {
        return _this3._decrementOngoing();
      });
    },
    // unresolved / ongoing requests, used for tests:
    _ongoing: 0,
    _incrementOngoing: function _incrementOngoing() {
      this._ongoing++;
    },
    _decrementOngoing: function _decrementOngoing() {
      this._ongoing--;
    },
    _shouldWait: function _shouldWait() {
      return this._ongoing === 0;
    },
    _registerWaiter: function _registerWaiter() {
      var _this4 = this;

      this._waiter = function () {
        return _this4._shouldWait();
      };

      Ember.Test.registerWaiter(this._waiter);
    }
  });

  _exports.default = _default;
});