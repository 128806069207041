define("ember-svg-jar/inlined/soundcloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#F7620E\" d=\"M24.919 0C11.382 0 .409 11.193.409 25s10.973 25 24.51 25c13.536 0 24.51-11.193 24.51-25S38.454 0 24.918 0z\"/><path fill=\"#FFF\" d=\"M37.45 30.917H26.29V16.852a6.945 6.945 0 012.436-.438c3.736 0 6.798 2.939 7.087 6.674a3.928 3.928 0 011.637-.357c2.216 0 4.013 1.833 4.013 4.093s-1.796 4.093-4.013 4.093zM25.512 30.917H24.18V18.09a7.11 7.11 0 011.332-.894v13.72zM23.448 30.917h-1.332V19.632c.184.077.363.164.537.26.232-.386.498-.749.795-1.085v12.11zM21.317 30.917h-1.332V19.188c.459.006.905.066 1.332.175v11.554zM19.185 30.917h-1.331V19.578a5.614 5.614 0 011.331-.343v11.682zM17.054 30.917h-1.331v-9.872c.389-.424.838-.787 1.331-1.078v10.95zM14.99 30.917h-1.332V23.34c.233.096.457.214.667.354.136-.585.36-1.145.665-1.66v8.882zM12.925 30.917h-.699c-.17 0-.336-.013-.5-.034v-7.79a3.793 3.793 0 011.2.031v7.793zM10.96 30.699a3.816 3.816 0 01-.998-.534v-6.354c.305-.227.642-.407.999-.534v7.421zM9.307 29.55a3.959 3.959 0 01-.932-2.562c0-.979.351-1.873.932-2.561v5.122z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});