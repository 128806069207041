define("kitsu-shared/components/occludable-area", ["exports", "kitsu-shared/templates/components/occludable-area", "client/utils/observer-manager", "ember-batcher/batcher"], function (_exports, _occludableArea, _observerManager, _batcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Occlusion based off work from LinkedIn.
   * Original source @ https://github.com/sreedhar7/ember-occludable-components
   *
   * {{#occludable-area bufferClass="class-name"}}
   *   {{my-component}}
   * {{/occludable-area}}
   */
  var _default = Ember.Component.extend({
    layout: _occludableArea.default,
    classNames: ['occludable-area'],
    shouldRender: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var element = Ember.get(this, 'element');
      element.classList.add(Ember.get(this, 'bufferClass'));
      var observe = (0, _observerManager.default)(this._getObserverOptions());
      this.unobserve = observe(element, function (entry) {
        if (entry && entry.isIntersecting) {
          _this.isVisible();

          if (_this.unobserve) {
            _this.unobserve();
          }
        }
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments); // Buffer class is no longer needed once the component has been rendered


      if (Ember.get(this, 'shouldRender')) {
        Ember.get(this, 'element').classList.remove(Ember.get(this, 'bufferClass'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.unobserve) {
        this.unobserve();
      }
    },
    isVisible: function isVisible() {
      var _this2 = this;

      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      (0, _batcher.mutateDOM)(function () {
        if (Ember.get(_this2, 'isDestroyed')) {
          return;
        }

        (0, _batcher.mutateDOM)(function () {
          if (Ember.get(_this2, 'isDestroyed')) {
            return;
          }

          Ember.set(_this2, 'shouldRender', true);
        });
      });
    },

    /**
     * Returns the config passed to the IntersectionObserver
     *
     * @returns {Object} RootMargin config
     */
    _getObserverOptions: function _getObserverOptions() {
      var defaultOptions = this._getDefaultRootMargin();

      var rootMargin = Object.assign(defaultOptions, Ember.get(this, 'rootMargin') || {});
      rootMargin = "".concat(rootMargin.top, "px ").concat(rootMargin.right, "px ").concat(rootMargin.bottom, "px ").concat(rootMargin.left, "px");
      return {
        root: null,
        rootMargin: rootMargin,
        threshold: 0
      };
    },
    _getDefaultRootMargin: function _getDefaultRootMargin() {
      return {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      };
    }
  });

  _exports.default = _default;
});