define("ember-cli-meta-tags/templates/components/head-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NdPFmNKS",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,[\"headTag\",\"content\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-meta-tags/templates/components/head-tag.hbs"
    }
  });

  _exports.default = _default;
});