define("ember-apollo-client/utils/copy-with-extras", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = copyWithExtras;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var extraCopyProperties = ['__typename'];

  function copyWithExtras(obj, seen, copies) {
    var ret, loc, key; // primitive data types are immutable, just return them.

    if (_typeof(obj) !== 'object' || obj === null) {
      return obj;
    } // avoid cyclical loops


    if ((loc = seen.indexOf(obj)) >= 0) {
      return copies[loc];
    }

    if (Array.isArray(obj)) {
      ret = obj.slice();
      loc = ret.length;

      while (--loc >= 0) {
        ret[loc] = copyWithExtras(ret[loc], seen, copies);
      }
    } else if (obj instanceof Date) {
      ret = new Date(obj.getTime());
    } else {
      ret = {};

      for (key in obj) {
        // support Null prototype
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          continue;
        } // Prevents browsers that don't respect non-enumerability from
        // copying internal Ember properties


        if (key.substring(0, 2) === '__') {
          continue;
        }

        ret[key] = copyWithExtras(obj[key], seen, copies);
      }

      extraCopyProperties.forEach(function (propertyName) {
        return ret[propertyName] = obj[propertyName];
      });
    }

    seen.push(obj);
    copies.push(ret);
    return ret;
  }
});