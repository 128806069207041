define("ember-svg-jar/inlined/gender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 5A2.5 2.5 0 105.001.001 2.5 2.5 0 005 5zm0 1.25c-1.669 0-5 .838-5 2.5V10h10V8.75c0-1.662-3.331-2.5-5-2.5z\" fill=\"#686868\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10"
    }
  };
  _exports.default = _default;
});