define("ember-svg-jar/inlined/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.5.25A4.372 4.372 0 00.125 4.625C.125 7.906 4.5 12.75 4.5 12.75s4.375-4.844 4.375-8.125A4.372 4.372 0 004.5.25zm0 5.937a1.563 1.563 0 11.001-3.126A1.563 1.563 0 014.5 6.187z\" fill=\"#686868\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "9",
      "height": "13",
      "viewBox": "0 0 9 13"
    }
  };
  _exports.default = _default;
});