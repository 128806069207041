define('ember-flickity/components/em-flickity', ['exports', 'ember-flickity/templates/components/em-flickity'], function (exports, _emFlickity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    layout: _emFlickity.default,
    classNames: ["flickity-wrapper"],
    _widget: null,
    showSlides: false,
    events: null,

    // some default flickity settings
    cellAlign: "center",
    contain: true,
    friction: 0.8,
    pageDots: false,
    selectedAttraction: 0.125,
    setGallerySize: false,

    optionKeys: Ember.computed(function getOptionKeys() {
      return ["draggable", "freeScroll", "wrapAround", "groupCells", "autoPlay", "fullscreen", "adaptiveHeight", "watchCSS", "asNavFor", "hash", "dragThreshold", "selectedAttraction", "friction", "freeScrollFriction", "imagesLoaded", "lazyLoad", "bgLazyLoad", "cellSelector", "initialIndex", "accessibility", "setGallerySize", "resize", "cellAlign", "contain", "percentPosition", "rightToLeft", "prevNextButtons", "pageDots", "arrowShape"];
    }),

    eventKeys: Ember.computed(function getEventKeys() {
      return ["ready", "change", "select", "settle", "scroll", "dragStart", "dragMove", "dragEnd", "pointerDown", "pointerMove", "pointerUp", "staticClick", "lazyLoad", "bgLazyLoad", "fullscreenChange"];
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.later(function () {
        if (Ember.get(_this, "showSlides")) {
          Ember.set(_this, "_widget", _this.$().flickity(_this._getOptions()));
        }
      }, 0);
    },
    willDestroyElement: function willDestroyElement() {
      var $widget = Ember.get(this, "_widget");

      if ($widget) {
        Ember.get(this, "eventKeys").forEach(function (evt) {
          return $widget.off(evt);
        });
        $widget.flickity("destroy");
      }
    },
    _setupEvents: function _setupEvents() {
      var _this2 = this;

      var eventHandlers = {};
      var events = Ember.get(this, "events");
      var eventsList = Ember.get(this, "eventKeys");
      var eventsFromParameters = true;

      if (events) {
        eventsList = Object.keys(events);
        eventsFromParameters = false;
      }

      eventsList.forEach(function (key) {
        var eventFunc = eventsFromParameters ? Ember.get(_this2, key) : events[key];
        if (eventFunc) {
          eventHandlers[key] = function () {
            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
              args[_key] = arguments[_key];
            }

            Ember.run.later(function () {
              var $widget = Ember.get(_this2, "_widget").data("flickity");
              eventFunc.apply(undefined, args.concat([$widget]));
            }, 0);
          };
        }
      });

      return eventHandlers;
    },
    _getOptions: function _getOptions() {
      var _Ember;

      var propKeys = Ember.get(this, "optionKeys");
      var props = (_Ember = Ember).getProperties.apply(_Ember, [this].concat(_toConsumableArray(propKeys)));

      Object.keys(props).forEach(function (prop) {
        if (!props[prop] && typeof props[prop] !== "boolean") {
          delete props[prop];
        }
      });

      var events = this._setupEvents() || {};
      //console.log(events);
      if (Object.keys(events).length > 0) {
        props.on = events;
      }

      return props;
    }
  });
});