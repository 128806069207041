define("ember-svg-jar/inlined/upvote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M544.5 279L657 429H432z\"/>",
    "attrs": {
      "width": "225",
      "height": "150",
      "viewBox": "432 279 225 150"
    }
  };
  _exports.default = _default;
});