define('ember-can/mixins/can', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    canService: Ember.inject.service('can'),

    init: function init() {
      this._super.apply(this, arguments);

      Ember.deprecate('Usage of ember-can mixin is deprecated, define your own "can" and "cannot" methods base on "can" service.', false, {
        id: 'ember-can.deprecate-can-mixin',
        until: '2.0.0',
        url: 'https://github.com/minutebase/ember-can#quick-example'
      });
    },
    can: function can(abilityName, resource, properties) {
      return this.get('canService').can(abilityName, resource, properties);
    },
    cannot: function cannot(abilityName, resource, properties) {
      return !this.can(abilityName, resource, properties);
    }
  });
});