define('ember-sortable/components/sortable-group', ['exports', 'ember-sortable/templates/components/sortable-group', 'ember-invoke-action'], function (exports, _sortableGroup, _emberInvokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var a = Ember.A;
  var NO_MODEL = {};

  exports.default = Ember.Component.extend({
    layout: _sortableGroup.default,

    attributeBindings: ['data-test-selector'],

    /**
      @property direction
      @type string
      @default y
    */
    direction: 'y',

    /**
      @property model
      @type Any
      @default null
    */
    model: NO_MODEL,

    /**
      @property items
      @type Ember.NativeArray
    */
    items: Ember.computed(function () {
      return a();
    }),

    /**
      Position for the first item.
      If spacing is present, first item's position will have to change as well.
      @property itemPosition
      @type Number
    */
    itemPosition: Ember.computed(function () {
      var direction = this.get('direction');

      return this.get('sortedItems.firstObject.' + direction) - this.get('sortedItems.firstObject.spacing');
    }).volatile(),

    /**
      @property sortedItems
      @type Array
    */
    sortedItems: Ember.computed(function () {
      var items = a(this.get('items'));
      var direction = this.get('direction');

      return items.sortBy(direction);
    }).volatile(),

    /**
      Register an item with this group.
      @method registerItem
      @param {SortableItem} [item]
    */
    registerItem: function registerItem(item) {
      this.get('items').addObject(item);
    },


    /**
      De-register an item with this group.
      @method deregisterItem
      @param {SortableItem} [item]
    */
    deregisterItem: function deregisterItem(item) {
      this.get('items').removeObject(item);
    },


    /**
      Prepare for sorting.
      Main purpose is to stash the current itemPosition so
      we don’t incur expensive re-layouts.
      @method prepare
    */
    prepare: function prepare() {
      this._itemPosition = this.get('itemPosition');
    },


    /**
      Update item positions (relatively to the first element position).
      @method update
    */
    update: function update() {
      var _this = this;

      var sortedItems = this.get('sortedItems');
      // Position of the first element
      var position = this._itemPosition;

      // Just in case we haven’t called prepare first.
      if (position === undefined) {
        position = this.get('itemPosition');
      }

      sortedItems.forEach(function (item) {
        var dimension = void 0;
        var direction = _this.get('direction');

        if (!Ember.get(item, 'isDragging')) {
          Ember.set(item, direction, position);
        }

        // add additional spacing around active element
        if (Ember.get(item, 'isBusy')) {
          position += Ember.get(item, 'spacing') * 2;
        }

        if (direction === 'x') {
          dimension = 'width';
        }
        if (direction === 'y') {
          dimension = 'height';
        }

        position += Ember.get(item, dimension);
      });
    },


    /**
      @method commit
    */
    commit: function commit() {
      var items = this.get('sortedItems');
      var groupModel = this.get('model');
      var itemModels = items.mapBy('model');
      var draggedItem = items.findBy('wasDropped', true);
      var draggedModel = void 0;

      if (draggedItem) {
        Ember.set(draggedItem, 'wasDropped', false); // Reset
        draggedModel = Ember.get(draggedItem, 'model');
      }

      delete this._itemPosition;

      Ember.run.schedule('render', function () {
        items.invoke('freeze');
      });

      Ember.run.schedule('afterRender', function () {
        items.invoke('reset');
      });

      Ember.run.next(function () {
        Ember.run.schedule('render', function () {
          items.invoke('thaw');
        });
      });

      if (groupModel !== NO_MODEL) {
        (0, _emberInvokeAction.invokeAction)(this, 'onChange', groupModel, itemModels, draggedModel);
      } else {
        (0, _emberInvokeAction.invokeAction)(this, 'onChange', itemModels, draggedModel);
      }
    }
  });
});