define("ember-svg-jar/inlined/newtab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans\" d=\"M21.188 9.281L19.78 10.72 24.063 15H4v2h20.063l-4.282 4.281 1.407 1.438 6-6 .718-.719-.718-.719-6-6z\" font-family=\"Bitstream Vera Sans\" overflow=\"visible\"/>",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});