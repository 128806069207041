define("ember-svg-jar/inlined/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M46 25c0-3.054-1.736-5.72-4.269-7.067.692-2.637.017-5.568-2.04-7.625a7.872 7.872 0 00-5.587-2.302c-.685 0-1.371.088-2.038.263C30.72 5.736 28.054 4 25 4s-5.72 1.736-7.067 4.269a8.03 8.03 0 00-2.038-.263c-2.026 0-4.05.765-5.587 2.302-2.057 2.057-2.732 4.987-2.04 7.625C5.736 19.28 4 21.946 4 25s1.736 5.72 4.269 7.067c-.692 2.637-.017 5.568 2.04 7.625a7.872 7.872 0 005.587 2.302 8.03 8.03 0 002.038-.263C19.28 44.264 21.946 46 25 46s5.72-1.736 7.067-4.269a8.03 8.03 0 002.038.263c2.026 0 4.05-.765 5.587-2.302 2.057-2.057 2.732-4.987 2.04-7.625C44.264 30.72 46 28.054 46 25zm-24 7.414l-6.707-6.707 1.414-1.414L22 29.586l11.293-11.293 1.414 1.414L22 32.414z\"/>",
    "attrs": {
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});