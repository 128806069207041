define("ember-svg-jar/inlined/nsfw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20 6h-9L9 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-1-2-2-2zm-5 10h-1.5l-1-1.7-1 1.7H10l1.8-3-1.8-3h1.5l1 1.7 1-1.7H15l-1.8 3 1.8 3zm-6 0H7.5l-1-1.7-1 1.7H4l1.8-3L4 10h1.5l1 1.7 1-1.7H9l-1.8 3L9 16zm12 0h-1.5l-1-1.7-1 1.7H16l1.8-3-1.8-3h1.5l1 1.7 1-1.7H21l-1.8 3 1.8 3z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});