define('ember-can/helpers/can', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    can: Ember.inject.service(),

    ability: null,
    propertyName: null,

    compute: function compute(_ref, properties) {
      var _ref2 = _slicedToArray(_ref, 2),
          abilityString = _ref2[0],
          model = _ref2[1];

      var service = this.get('can');

      var _service$parse = service.parse(abilityString),
          abilityName = _service$parse.abilityName,
          propertyName = _service$parse.propertyName;

      var ability = service.abilityFor(abilityName, model, properties);

      this._removeAbilityObserver();
      this._addAbilityObserver(ability, propertyName);

      return ability.get(propertyName);
    },
    destroy: function destroy() {
      this._removeAbilityObserver();
      return this._super.apply(this, arguments);
    },
    _addAbilityObserver: function _addAbilityObserver(ability, propertyName) {
      this.setProperties({ ability: ability, propertyName: propertyName });
      this.addObserver('ability.' + propertyName, this, 'recompute');
    },
    _removeAbilityObserver: function _removeAbilityObserver() {
      this.removeObserver('ability.' + this.get('propertyName'), this, 'recompute');
    }
  });
});