define("ember-svg-jar/inlined/dailymotion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#0066DC\" d=\"M50 25c0 13.807-11.193 25-25 25S0 38.807 0 25 11.193 0 25 0s25 11.193 25 25z\"/><path fill=\"#FFF\" d=\"M31.79 14.51l-4.373.92v6.583c-.996-1.293-2.381-1.914-4.18-1.914-1.882 0-3.571.702-4.955 2.081-1.55 1.52-2.38 3.46-2.38 5.627 0 2.363.885 4.388 2.602 5.936 1.303 1.182 2.851 1.771 4.65 1.771 1.77 0 3.099-.477 4.399-1.77v1.69h4.236V14.508v.001zm-7.665 16.986c-2.242 0-3.849-1.605-3.849-3.714 0-2.027 1.606-3.744 3.682-3.744 2.047 0 3.626 1.632 3.626 3.799 0 2.079-1.579 3.658-3.46 3.658l.001.001z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});