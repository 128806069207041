define("ember-svg-jar/inlined/checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans\" d=\"M19.281 5.281L9 15.563 4.719 11.28 3.28 12.72l5 5 .719.687.719-.687 11-11L19.28 5.28z\" color=\"#000\" font-family=\"Bitstream Vera Sans\" overflow=\"visible\"/>",
    "attrs": {
      "version": "1",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});