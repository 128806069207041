define("ember-svg-jar/inlined/instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#517FA4\" d=\"M0 117c0-13.807 11.193-25 25-25s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z\"/><path fill=\"#FFF\" d=\"M33.876 123.729c0 1.16-.944 2.1-2.104 2.1H18.305a2.103 2.103 0 01-2.103-2.1v-8.981h3.277a5.985 5.985 0 00-.442 2.252 6.006 6.006 0 006.002 5.998c3.31 0 6.002-2.69 6.002-5.998 0-.796-.161-1.555-.442-2.252h3.277v8.98zm-11.97-8.981a3.858 3.858 0 013.133-1.61c1.29 0 2.43.637 3.132 1.61.458.634.733 1.41.733 2.252a3.865 3.865 0 01-7.73 0c.001-.842.275-1.618.733-2.252zm11.082-6.187l.485-.002v3.717l-3.708.014-.013-3.72 3.236-.009zm-1.216-2.53H18.305a4.246 4.246 0 00-4.242 4.24v13.458a4.246 4.246 0 004.242 4.239h13.467a4.246 4.246 0 004.243-4.24v-13.457a4.246 4.246 0 00-4.243-4.24z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 92 50 50"
    }
  };
  _exports.default = _default;
});